import React, { useEffect, useState } from "react";
import _ from "lodash";
import { NodeTypes } from "../rulemaps/RuleMapDataCommon";
import SelectInput from "../../common/input/SingleSelect";
import ReadOnly from "../../common/input/ReadOnly";
import ExpandCollapse from "../../common/layout/ExpandCollapse";

function GroupRuleMapEditFieldDialogRuleFieldsSection({
  ruleOptions,
  changes,
  isAdd,
  errors,
  onRuleChange,
}) {
  const DEFAULT_RULE_OPTION = {
    value: "-1",
    label: "(No rule associated with this field)",
  };

  const [currentRule, setCurrentRule] = useState({ ...DEFAULT_RULE_OPTION });
  const [selectedRule, setSelectedRule] = useState({ ...DEFAULT_RULE_OPTION });

  // Remove the All Rules option (always the last in the array) from the rule select since we will never (well, for now) allow the user to push a value to that rule.
  let selectRules = [];
  selectRules.push({
    value: "-1",
    label: "(Select a Rule)",
  });
  const filteredRules = ruleOptions.filter(
    (r, idx) => idx < ruleOptions.length - 1
  );
  selectRules = selectRules.concat(filteredRules);

  useEffect(() => {
    // Save the current rule on load since we don't want this value to change as the user selects other rules
    const rule = getCurrentRule();
    setCurrentRule(rule);
  }, []);

  useEffect(() => {
    // Set the selected rule when the current value changes
    const rule = getSelectedRule();
    setSelectedRule(rule);
  }, [changes.ruleId]);

  function shouldDisableRuleSelection() {
    let disable = false;

    if (
      changes.type === NodeTypes.CodeArtifact ||
      changes.type === NodeTypes.CodeArtifactInput ||
      changes.type === NodeTypes.CodeArtifactOutput
    ) {
      disable = true;
    }

    return disable;
  }

  function getSelectedRule() {
    // Default to most specific rule - first in the array
    if ((selectRules || []).length < 1) return { ...DEFAULT_RULE_OPTION };

    const selected = { ...selectRules[0] };

    if (
      !_.isEmpty(changes.ruleId) &&
      changes.ruleId !== "-1" &&
      selectRules.findIndex((r) => r.value === changes.ruleId) >= 0
    ) {
      selected.value = changes.ruleId;
      selected.label =
        ruleOptions.find((r) => r.value === changes.ruleId)?.label ??
        "Rule not found";
    }

    return selected;
  }

  function getCurrentRule() {
    let rule = { ...DEFAULT_RULE_OPTION };

    if (
      (ruleOptions || []).length > 0 &&
      !_.isEmpty(changes.ruleId) &&
      changes.ruleId !== "-1"
    ) {
      rule.value = changes.ruleId;
      rule.label =
        ruleOptions.find((r) => r.value === changes.ruleId)?.label ??
        "Rule not found";
    }

    return rule;
  }

  function handleRuleChange(option) {
    onRuleChange(option);
  }

  function getWarningMessage(msg, ruleLabel) {
    const warning = (
      <div className="flex-row-with-wrap" style={{ marginBottom: "20px" }}>
        <span
          className="material-icons"
          style={{ paddingRight: "5px", color: "var(--notify-info)" }}
        >
          warning
        </span>{" "}
        <b>Note:</b>
        <div
          style={{
            borderLeft: "4px solid var(--notify-info)",
            paddingLeft: "4px",
          }}
        >
          {msg} <i>{ruleLabel}</i>
        </div>
      </div>
    );

    return warning;
  }

  const currentRuleIndex = ruleOptions.findIndex(
    (r) => r.value === currentRule.value
  );
  const selectedRuleIndex = ruleOptions.findIndex(
    (r) => r.value === selectedRule.value
  );
  const hasCurrentRule = currentRuleIndex >= 0;
  const hasWarning =
    hasCurrentRule &&
    selectedRule.value !== "-1" &&
    (selectedRuleIndex < currentRuleIndex ||
      currentRuleIndex < selectedRuleIndex);

  const hasError = !_.isEmpty(errors.ruleId || "");

  return (
    <ExpandCollapse
      id="groupRuleDialogRule"
      title="Rule Selection"
      subTitle={
        hasWarning ? (
          <span
            className="material-icons"
            style={{ paddingLeft: "5px", color: "var(--notify-info)" }}
          >
            warning
          </span>
        ) : hasError ? (
          <span
            className="material-icons"
            style={{ paddingLeft: "5px", color: "var(--notify-warning)" }}
          >
            warning
          </span>
        ) : (
          ""
        )
      }
      defaultState={isAdd}
    >
      {hasCurrentRule &&
        selectedRule.value !== "-1" &&
        selectedRuleIndex < currentRuleIndex &&
        getWarningMessage(
          "On save, this field will be copied to rule",
          ruleOptions[selectedRuleIndex]?.label
        )}
      {hasCurrentRule &&
        selectedRule.value !== "-1" &&
        currentRuleIndex < selectedRuleIndex &&
        getWarningMessage(
          "On save, this field will be deleted from the current rule and moved to rule",
          ruleOptions[currentRuleIndex]?.label
        )}
      {hasCurrentRule && (
        <ReadOnly
          id="currentRule"
          label="Current Rule"
          name="currentRule"
          value={currentRule.label}
        />
      )}
      <SelectInput
        id="ruleId"
        name="ruleId"
        label="Save to Rule"
        options={selectRules}
        value={selectedRule}
        onChange={handleRuleChange}
        placeholder="Select a Rule"
        disabled={shouldDisableRuleSelection()}
        error={errors.ruleId}
      />
    </ExpandCollapse>
  );
}

export default GroupRuleMapEditFieldDialogRuleFieldsSection;
