/* eslint-disable no-undef */
import { handleResponse, getCommonHeaders, getQueryString } from "./apiUtils";

export function apiLoadGroupRuleMaps(search, token, signal) {
  const uri = API_URL + "/groups" + getQueryString();
  let where = [];

  // Free Form Search support
  if (search && search.freeFormSearch && search.freeFormSearch.length > 0) {
    where.push({ name: "groupId", operator: "notnull" });
    where[where.length - 1].subQueryItems = [];
    where[where.length - 1].subQueryItems.push({
      name: "groupId",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "name",
      operator: "=",
      values: [search.freeFormSearch],
    });
  }

  if (search && search.active) {
    where.push({ name: "TEMPORALITEMMATCH", operator: "=", values: [] });
  }
  if (search && search.groupId && search.groupId.length > 0)
    where.push({ name: "groupId", operator: "=", values: [search.groupId] });
  if (search && search.name && search.name.length > 0)
    where.push({ name: "name", operator: "=", values: [search.name] });
  if (search && search.clientId && search.clientId.value)
    where.push({
      name: "clientId",
      operator: "=",
      values: [search.clientId.value],
    });

  if (where.length === 0) {
    where.push({ name: "groupId", operator: "notnull", values: [] });
  }

  const data = { where, orderBy: search.orderBy };
  const pageSize = search.pageSize || 10;
  const page = search.pageNumber || 1;

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri + `&page=${page}&pageSize=${pageSize}`, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadGroupRuleMap(groupId, token, signal) {
  let uri =
    API_URL + "/rulemap/" + encodeURIComponent(groupId) + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiSaveGroupRuleMap(m, token, signal) {
  const model = m.model;

  let uri = API_URL + "/rulemap" + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(model),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

// Function names for use in useApi to show in coverage tracking since obsfucation messes up function.name.
apiLoadGroupRuleMaps.funcName = "apiLoadGroupRuleMaps";
apiLoadGroupRuleMap.funcName = "apiLoadGroupRuleMap";
apiSaveGroupRuleMap.funcName = "apiSaveGroupRuleMap";
