import React, { useState, useEffect } from "react";
import _ from "lodash";
import Spinner from "../../common/ui/Spinner";
import {
  notifySuccess,
  notifyWarn,
} from "../../../services/NotificationService";
import {
  createRuleViewModel,
  emptyRule,
  emptyRuleChangeHistoryRecord,
  emptySavedRuleEditState,
  fromViewModel,
} from "../../../viewmodels/rulesVm";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRules } from "../../../contexts/RulesContext";
import { useRuleTables } from "../../../contexts/RuleTablesContext";
import { useAuth } from "../../../contexts/AuthContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import {
  apiAddRule,
  apiLoadGroupRule,
  apiLoadRule,
  apiUpdateRule,
} from "../../../api/RuleApi";
import Authorize from "../../common/layout/Authorize";
import {
  handleCollapseExpandAll,
  handleCollapseExpandSection,
} from "../../../services/General";
import {
  StyledBackButtonDiv,
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledRowDiv,
  StyledScreenHelpWithBackDiv,
} from "../../common/layout/CommonStyledControls";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import ActionMenu from "../../common/ui/ActionMenu";
import InstantSearchInput from "../../common/input/InstantSearchInput";
import { useMobile } from "../../../hooks/useMobile";
import RuleTablesList from "./RuleTablesList";
import useApi from "../../../hooks/useApi";
import DestinationRules from "./DestinationRules";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import RuleAreaSummary from "./RuleAreaSummary";
import RuleAreaEffectiveRange from "./RuleAreaEffectiveRange";
import RuleAreaGroupsAndTags from "./RuleAreaGroupsAndTags";
import RuleAreaArtifacts from "./RuleAreaArtifacts";
import RuleAreaConditionals from "./RuleAreaConditionals";
import RuleAreaIncludedDataValues from "./RuleAreaIncludedDataValues";
import RuleAreaIncludedRejections from "./RuleAreaIncludedRejections";
import RuleAreaExcludedRejections from "./RuleAreaExcludedRejections";
import RuleSetDatesDialog from "./RuleSetDatesDialog";
import { filterItems } from "./RuleCommonFunctions";
import HelpLink from "../../common/ui/HelpLink";
import RuleChangeHistoryDialog from "./RuleChangeHistoryDialog";
import RuleChangeHistory from "./RuleChangeHistory";

function Rule() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { rulesData, setRulesData } = useRules();
  const { ruleTablesData, setRuleTablesData } = useRuleTables();
  const { loading, api: apiLoad } = useApi(apiLoadRule);
  const { loading: loadingGroup, api: apiLoadGroup } = useApi(apiLoadGroupRule);
  const { loading: adding, api: apiAdd } = useApi(apiAddRule);
  const { loading: updating, api: apiUpdate } = useApi(apiUpdateRule);
  const { isMobileSize, isTabletSize } = useMobile();
  const [errors, setErrors] = useState({});
  const [ruleChanges, setRuleChanges] = useState(emptyRule);
  const [editRuleTable, setEditRuleTable] = useState(null);
  const [showDateModal, setShowDateModal] = useState(false);
  const [itemEffectiveDate, setItemEffectiveDate] = useState(null);
  const [itemTerminationDate, setItemTerminationDate] = useState(null);
  const [dateSetArray, setDateSetArray] = useState("");
  const [dateSetId, setDateSetId] = useState("");
  const location = useLocation();
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [copyToRuleParameters, setCopyToRuleParameters] = useState({});
  const [changeHistoryRecord, setChangeHistoryRecord] = useState({
    ...emptyRuleChangeHistoryRecord,
  });
  const [showChangeHistoryModal, setShowChangeHistoryModal] = useState(false);

  const [collapsedState, setCollapsedState] = useState([
    { name: "Rule", collapsed: false },
    { name: "Effective Range", collapsed: true },
    { name: "Groups and Tags", collapsed: true },
    { name: "Artifacts", collapsed: true },
    { name: "Conditionals", collapsed: true },
    { name: "Included Data Values", collapsed: true },
    { name: "Included Rejections", collapsed: true },
    { name: "Excluded Rejections", collapsed: true },
    { name: "Rule Tables", collapsed: true },
    { name: "Destination Rules for Copy", collapsed: true },
    { name: "Change History", collapsed: true },
  ]);

  const ruleId = params && params.id;

  useEffect(() => {
    if (auth.authenticated) {
      loadRule();
    }
  }, [auth.authenticated, params?.id]);

  useEffect(() => {
    if (rulesData.rule) {
      setRuleChanges(rulesData.rule);
    } else {
      setRuleChanges(emptyRule);
    }
  }, [rulesData.rule]);

  async function callRuleApi(ruleId) {
    if (location.pathname.includes("grouprule")) {
      apiLoadGroup.call(ruleId, (result) => {
        const vm = createRuleViewModel(result);
        postLoadRule(vm);
      });
    } else {
      apiLoad.call(ruleId, (result) => {
        const vm = createRuleViewModel(result);
        postLoadRule(vm);
      });
    }
  }

  function postLoadRule(vm) {
    // If rule is being loaded after sending user to the rule table definition screen to create a new one there and return,
    //   load the current edit state from the context.
    if (
      rulesData.savedRuleEditState &&
      rulesData.savedRuleEditState.continueRuleEdit === true
    ) {
      continuePreviousRuleEdit(vm);
    } else {
      setEditRuleTable(null);
      setRulesData({
        type: ContextProviderActions.loadRule,
        payload: vm,
      });
      setRuleTablesData({
        type: ContextProviderActions.loadRuleTables,
        payload: {
          ruleTables: vm.ruleTables,
          count: vm.ruleTables.length,
        },
      });
      setRuleChanges(vm);
    }
  }

  async function loadRule() {
    if (!ruleId) {
      setRulesData({
        type: ContextProviderActions.loadRule,
        payload: emptyRule,
      });
      setRuleChanges(emptyRule);
      return;
    }

    callRuleApi(ruleId);
  }

  function continuePreviousRuleEdit(vm) {
    // Merge the loaded rule with previous edits
    const newVm = { ...vm, ...rulesData.savedRuleEditState.ruleChanges };
    setRulesData({
      type: ContextProviderActions.loadRule,
      payload: newVm,
    });
    setRuleChanges(newVm);

    // Set the saved rule edit state
    setEditRuleTable({ ...rulesData.savedRuleEditState.ruleTableChanges });

    // Clear the saved edit state
    setRulesData({
      type: ContextProviderActions.saveRuleEditState,
      payload: { ...emptySavedRuleEditState },
    });

    // Collapse all sections and open Rule Tables
    handleCollapseExpandAll(true, collapsedState, setCollapsedState);
    handleCollapseExpandSection(
      "Rule Tables",
      false,
      collapsedState,
      setCollapsedState
    );
  }

  // This function handles the case where the user is creating a new rule table definition from the dialog and needs to go
  //   to that screen and then come back and continue editing here. So we save the state of the edits.
  function handleSaveStateAndAddRuleTableDefinition(editRuleTable) {
    // Note: continueRuleEdit is only set when coming back from the Rule Table Definition screen to indicate to put the rule
    //   into edit mode. This makes it so if the user ends up navigating away and going to a rule later, we don't
    //   accidentally try to continue the edit.
    setRulesData({
      type: ContextProviderActions.saveRuleEditState,
      payload: {
        ruleId: ruleId,
        ruleChanges: ruleChanges,
        ruleTableChanges: editRuleTable,
        continueRuleEdit: false,
        securityLevel:
          ruleChanges.description === "Report Templates"
            ? "ReportTemplate"
            : "Rule",
      },
    });
    navigate("/ruletabledefinition");
  }

  async function handleSubmit(event) {
    if (event) event.preventDefault();

    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }

    if (ruleId) {
      setShowChangeHistoryModal(true);
    } else {
      await handleSave(undefined, { ...ruleChanges });
    }
  }

  async function handleSave(event, updated) {
    if (event) event.preventDefault();
    const newVm = updated ? updated : { ...ruleChanges };

    if (!ruleId) {
      addRule(newVm);
    } else updateRule(newVm.id, newVm);
  }

  async function addRule(vm) {
    var model = fromViewModel(vm);
    apiAdd.call(model, (result) => {
      // TODO: Not finding this in the old code, but is this needed?
      // setRuleChanges(createRuleViewModel(result));
      notifySuccess("Rule saved successfully");

      // Navigate to the edit URL so user can save repeatedly. Replace the old create route in history so back works.
      navigate("/rule/" + result.id, { replace: true });
    });
  }

  async function updateRule(id, vm) {
    var model = fromViewModel(vm);
    apiUpdate.call({ id, model }, (result) => {
      loadRule();
      notifySuccess("Rule saved successfully");
    });
  }

  async function handleSaveDestinationRule(updatedDestRule, sourceRule) {
    apiUpdate.call(
      { id: updatedDestRule.id, model: updatedDestRule },
      (result) => {
        notifySuccess("Item copied to all destination rules successfully!");
        setRuleChanges(sourceRule);
      }
    );
  }

  function doesItemExistInArray(array, item) {
    if ((array || []).length === 0) return false;
    else return array.findIndex((r) => _.toLower(r) === _.toLower(item)) >= 0;
  }

  function addItemToArrayIfNotExist(array, item) {
    if ((array || []).length === 0) array.push(item);
    else if (array.findIndex((r) => _.toLower(r) === _.toLower(item)) < 0)
      array.push(item);
  }

  function formIsValid() {
    const _errors = {};
    if (_.has(ruleChanges, "priority") && isNaN(ruleChanges.priority))
      _errors.priority = "Priority must be an integer";
    if (_.has(ruleChanges, "description") && _.isEmpty(ruleChanges.description))
      _errors.description = "Must enter a description for the rule";

    // jon, Make sure all artifacts listed in each section are included in the Rule Artifacts
    let artifactsMissing = [];
    const currentRuleArtifacts = (ruleChanges.ruleArtifactsVm || "").split(",");

    // Remove priority if included on any artifact name
    for (let i = 0; i < currentRuleArtifacts.length; i++) {
      currentRuleArtifacts[i] =
        currentRuleArtifacts[i].indexOf(":") >= 0
          ? currentRuleArtifacts[i].substring(
              0,
              currentRuleArtifacts[i].indexOf(":")
            )
          : currentRuleArtifacts[i];
    }

    // Check for missing artifacts in Conditionals
    const artifactConditionals = ruleChanges.conditionals.filter(
      (r) => _.toLower(r.attachedToType) === "artifact"
    );
    for (let i = 0; i < artifactConditionals.length; i++) {
      if (
        !doesItemExistInArray(
          currentRuleArtifacts,
          artifactConditionals[i].attachedTo
        )
      ) {
        addItemToArrayIfNotExist(
          artifactsMissing,
          artifactConditionals[i].attachedTo
        );
      }
    }

    // Check for missing artifacts in Included Data Values
    //  In this case, do not consider Keys that start with "flag" since these are not real artifacts.
    const artifactDataValues = ruleChanges.includedDataValues.filter(
      (r) => !_.startsWith(_.toLower(r.idKey), "flag")
    );
    for (let i = 0; i < artifactDataValues.length; i++) {
      if (
        !doesItemExistInArray(
          currentRuleArtifacts,
          artifactDataValues[i].attachedArtifact
        )
      ) {
        addItemToArrayIfNotExist(
          artifactsMissing,
          artifactDataValues[i].attachedArtifact
        );
      }
    }

    // Check for missing artifacts in Included Rejections
    //  In this case, do not consider Keys that start with "flag" since these are not real artifacts.
    const artifactIncludedRejections = ruleChanges.includedRejections.filter(
      (r) => !_.startsWith(_.toLower(r.rejectionKey), "flag")
    );
    for (let i = 0; i < artifactIncludedRejections.length; i++) {
      if (
        !doesItemExistInArray(
          currentRuleArtifacts,
          artifactIncludedRejections[i].attachedArtifact
        )
      ) {
        addItemToArrayIfNotExist(
          artifactsMissing,
          artifactIncludedRejections[i].attachedArtifact
        );
      }
    }

    // Check for missing artifacts in Excluded Rejections
    //  In this case, do not consider Keys that start with "flag" since these are not real artifacts.
    const artifactExcludedRejections = ruleChanges.excludedRejections.filter(
      (r) => !_.startsWith(_.toLower(r.rejectionKey), "flag")
    );
    for (let i = 0; i < artifactExcludedRejections.length; i++) {
      if (
        !doesItemExistInArray(
          currentRuleArtifacts,
          artifactExcludedRejections[i].attachedArtifact
        )
      ) {
        addItemToArrayIfNotExist(
          artifactsMissing,
          artifactExcludedRejections[i].attachedArtifact
        );
      }
    }

    if (!_.isEmpty(artifactsMissing)) {
      _errors.ruleArtifacts =
        "The following artifacts used on this rule must be included here: " +
        artifactsMissing;
      handleCollapseExpandSection(
        "Artifacts",
        false,
        collapsedState,
        setCollapsedState
      );
    }

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function isGroupRule() {
    return ruleChanges.id === "GroupRule";
  }

  function handleRuleChange({ target }) {
    let ruleChanged = { ...ruleChanges, [target.name]: target.value };
    setRuleChanges(ruleChanged);
  }

  function handleRuleSearchTextChange(value) {
    let ruleChanged = { ...ruleChanges, textSearch: value };

    // jon, 4/11/23: When filter changes, remove isNew flag from all arrays since this is only used when
    //   a new item is added while a filter is applied. But we don't want it to persist after the filter changes.
    ruleChanged.conditionals.forEach((a) => (a.isNew = false));
    ruleChanged.includedDataValues.forEach((a) => (a.isNew = false));
    ruleChanged.includedRejections.forEach((a) => (a.isNew = false));
    ruleChanged.excludedRejections.forEach((a) => (a.isNew = false));

    setRuleChanges(ruleChanged);
    setRulesData({
      type: ContextProviderActions.loadRule,
      payload: ruleChanged,
    });
  }

  function handleStagingRuleChange({ target }) {
    let ruleChanged = { ...ruleChanges, stagingOnly: target.checked };
    setRuleChanges(ruleChanged);
  }

  async function handleIndexedCopy(array, index) {
    let newRule = _.cloneDeep(ruleChanges);
    if (
      (newRule.destinationRules || []).length === 0 ||
      newRule.destinationRules.filter((dr) => dr.selected).length === 0
    ) {
      notifyWarn("No destination rules are selected for copy");
      return;
    }

    setShowCopyModal(true);
    setCopyToRuleParameters({ newRule: newRule, array: array, index: index });
  }

  async function performCopyToRule() {
    setShowCopyModal(false);
    const newRule = copyToRuleParameters.newRule;
    const array = copyToRuleParameters.array;
    const index = copyToRuleParameters.index;
    setCopyToRuleParameters({});

    // jon, 3/31/23: handle filter arrays
    let item = filterItems(newRule, ruleTablesData)[array][index];
    if (_.isNull(item)) return;

    let items = [];
    items.push(item);

    for (const dr of newRule.destinationRules.filter((dr) => dr.selected)) {
      items.forEach((i) => {
        let exist = null;
        if (array === "conditionals") {
          exist = dr.conditionals.find(
            (c) => c.attachedTo === i.attachedTo && c.name === i.name
          );
          if (!exist) dr.conditionals.push(i);
          else Object.assign(exist, i);
        }
        if (array === "includedDataValues") {
          exist = dr.includedDataValues.find(
            (c) =>
              c.attachedArtifact === i.attachedArtifact &&
              c.idKey === i.idKey &&
              c.key === i.key
          );
          if (!exist) dr.includedDataValues.push(i);
          else Object.assign(exist, i);
        }
        if (array === "includedRejections") {
          exist = dr.includedRejections.find(
            (c) =>
              c.attachedArtifact === i.attachedArtifact &&
              c.rejectionKey === i.rejectionKey &&
              c.code === i.code
          );
          if (!exist) dr.includedRejections.push(i);
          else Object.assign(exist, i);
        }
        if (array === "excludedRejections") {
          exist = dr.excludedRejections.find(
            (c) =>
              c.attachedArtifact === i.attachedArtifact &&
              c.rejectionKey === i.rejectionKey &&
              c.code === i.code
          );
          if (!exist) dr.excludedRejections.push(i);
          else Object.assign(exist, i);
        }
      });
      await handleSaveDestinationRule(dr, newRule);
    }
  }

  function handleEffectiveDateChange(date) {
    setRuleChanges({ ...ruleChanges, effectiveDate: date });
  }

  function handleTerminationDateChange(date) {
    setRuleChanges({ ...ruleChanges, terminationDate: date });
  }

  const handleOpenDates = (item, array, id) => {
    setItemEffectiveDate(item.effectiveDate);
    setItemTerminationDate(item.terminationDate);
    setDateSetArray(array);
    setDateSetId(id);
    setShowDateModal(true);
  };

  function handleCloseChangeHistoryDialog() {
    setShowChangeHistoryModal(false);
    setChangeHistoryRecord({ ...emptyRuleChangeHistoryRecord });
  }

  async function handleSaveChangeHistoryDialog(changeRecord) {
    setShowChangeHistoryModal(false);

    const _ruleChanges = { ...ruleChanges };
    if (!_ruleChanges.changeHistoryRecords) {
      _ruleChanges.changeHistoryRecords = [];
    }
    _ruleChanges.changeHistoryRecords.push({ ...changeRecord });

    await handleSave(undefined, _ruleChanges);
    setChangeHistoryRecord({ ...emptyRuleChangeHistoryRecord });
  }

  return (
    <Authorize>
      <ConfirmDialog
        title="Copy to Rule"
        question={`Are you sure you wish to copy this item to the selected destination rules?`}
        showModal={showCopyModal}
        onNo={() => setShowCopyModal(false)}
        onYes={performCopyToRule}
      />
      <RuleChangeHistoryDialog
        editItem={changeHistoryRecord}
        setEditItem={setChangeHistoryRecord}
        showDialog={showChangeHistoryModal}
        onCloseDialog={handleCloseChangeHistoryDialog}
        onSaveDialog={handleSaveChangeHistoryDialog}
      />
      <form onSubmit={handleSubmit}>
        <StyledBackButtonDiv>
          <button
            type="button"
            title="Return To Rules"
            className="btn btn-link btn-with-icon"
            onClick={() => navigate(-1)}
          >
            <i className="fa fa-angle-left"></i> Back
          </button>
          <StyledScreenHelpWithBackDiv>
            <HelpLink path="/Processing-Rules/Rule-Screen" label="Help" />
          </StyledScreenHelpWithBackDiv>
        </StyledBackButtonDiv>
        <StyledHeaderRowDiv>
          <h1>Processing Rule</h1>
          <InstantSearchInput
            id="textSearch"
            onChange={handleRuleSearchTextChange}
            value={ruleChanges.textSearch}
          />
          {!isGroupRule() && (
            <StyledHeaderRowButtonDiv>
              <button
                type="submit"
                className="btn btn-primary"
                style={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "86px",
                }}
              >
                <span className="material-icons">check</span>
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => navigate("/processingrules")}
                style={{ marginLeft: "12px" }}
              >
                Cancel
              </button>
            </StyledHeaderRowButtonDiv>
          )}
        </StyledHeaderRowDiv>

        {loading || loadingGroup || adding || updating ? (
          <Spinner />
        ) : (
          <>
            <ActionMenu
              title="Actions"
              items={[
                { value: "ExpandAll", label: "Expand All" },
                { value: "CollapseAll", label: "Collapse All" },
                {
                  value: "ViewChangeHistory",
                  label: "View Change History",
                  isLink: true,
                  show: auth.isAdmin,
                  url: `/auditrecord/processingrule/${ruleId}/0`,
                },
              ]}
              onSelectAction={(value, label) =>
                handleCollapseExpandAll(
                  value === "CollapseAll",
                  collapsedState,
                  setCollapsedState
                )
              }
            />
            <div className="container-fluid" style={{ marginTop: "5px" }}>
              <ExpandCollapseDetailSection
                sectionTitle="Rule"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Processing-Rules/Rule-Screen&anchor=rule"
              >
                <RuleAreaSummary
                  ruleChanges={ruleChanges}
                  handleRuleChange={handleRuleChange}
                  handleStagingRuleChange={handleStagingRuleChange}
                  errors={errors}
                />
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Effective Range"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Processing-Rules/Rule-Screen&anchor=effective-range"
              >
                <RuleAreaEffectiveRange
                  ruleChanges={ruleChanges}
                  handleEffectiveDateChange={handleEffectiveDateChange}
                  handleTerminationDateChange={handleTerminationDateChange}
                  errors={errors}
                />
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Groups and Tags"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Processing-Rules/Rule-Screen&anchor=groups-and-tags"
              >
                <RuleAreaGroupsAndTags
                  ruleChanges={ruleChanges}
                  handleRuleChange={handleRuleChange}
                  errors={errors}
                />
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Artifacts"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Processing-Rules/Rule-Screen&anchor=artifacts"
              >
                <RuleAreaArtifacts
                  ruleChanges={ruleChanges}
                  handleRuleChange={handleRuleChange}
                  errors={errors}
                />
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle={`Conditionals`}
                subTitle={`(${
                  filterItems(ruleChanges, ruleTablesData).conditionals.length
                })`}
                filtered={!_.isEmpty(ruleChanges.textSearch)}
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Processing-Rules/Rule-Screen&anchor=conditionals"
              >
                <RuleAreaConditionals
                  isSmallSize={isMobileSize || isTabletSize}
                  isGroupRule={isGroupRule()}
                  conditionals={
                    filterItems(ruleChanges, ruleTablesData).conditionals
                  }
                  ruleChanges={ruleChanges}
                  setRuleChanges={setRuleChanges}
                  handleOpenDates={handleOpenDates}
                  handleIndexedCopy={handleIndexedCopy}
                />
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle={`Included Data Values`}
                subTitle={`(${
                  filterItems(ruleChanges, ruleTablesData).includedDataValues
                    .length
                })`}
                filtered={!_.isEmpty(ruleChanges.textSearch)}
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Processing-Rules/Rule-Screen&anchor=included-data-values"
              >
                <RuleAreaIncludedDataValues
                  isSmallSize={isMobileSize || isTabletSize}
                  isGroupRule={isGroupRule()}
                  includedDataValues={
                    filterItems(ruleChanges, ruleTablesData).includedDataValues
                  }
                  ruleChanges={ruleChanges}
                  setRuleChanges={setRuleChanges}
                  handleOpenDates={handleOpenDates}
                  handleIndexedCopy={handleIndexedCopy}
                />
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle={`Included Rejections`}
                subTitle={`(${
                  filterItems(ruleChanges, ruleTablesData).includedRejections
                    .length
                })`}
                filtered={!_.isEmpty(ruleChanges.textSearch)}
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Processing-Rules/Rule-Screen&anchor=included-rejections"
              >
                <RuleAreaIncludedRejections
                  isSmallSize={isMobileSize || isTabletSize}
                  isGroupRule={isGroupRule()}
                  includedRejections={
                    filterItems(ruleChanges, ruleTablesData).includedRejections
                  }
                  ruleChanges={ruleChanges}
                  setRuleChanges={setRuleChanges}
                  handleOpenDates={handleOpenDates}
                  handleIndexedCopy={handleIndexedCopy}
                />
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle={`Excluded Rejections`}
                subTitle={`(${
                  filterItems(ruleChanges, ruleTablesData).excludedRejections
                    .length
                })`}
                filtered={!_.isEmpty(ruleChanges.textSearch)}
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Processing-Rules/Rule-Screen&anchor=excluded-rejections"
              >
                <RuleAreaExcludedRejections
                  isSmallSize={isMobileSize || isTabletSize}
                  isGroupRule={isGroupRule()}
                  excludedRejections={
                    filterItems(ruleChanges, ruleTablesData).excludedRejections
                  }
                  ruleChanges={ruleChanges}
                  setRuleChanges={setRuleChanges}
                  handleOpenDates={handleOpenDates}
                  handleIndexedCopy={handleIndexedCopy}
                />
              </ExpandCollapseDetailSection>
              {ruleId && (
                <ExpandCollapseDetailSection
                  sectionTitle={`Rule Tables`}
                  subTitle={`(${
                    filterItems(ruleChanges, ruleTablesData)
                      .ruleTableFilterCount
                  })`}
                  filtered={!_.isEmpty(ruleChanges.textSearch)}
                  collapsedState={collapsedState}
                  setCollapsedState={setCollapsedState}
                  helpLink="/Processing-Rules/Rule-Screen&anchor=rule-tables"
                >
                  <StyledRowDiv className="row">
                    <RuleTablesList
                      ruleId={ruleId}
                      ruleName={ruleChanges.description}
                      onAddRuleTableDefinition={
                        handleSaveStateAndAddRuleTableDefinition
                      }
                      savedEditRuleTable={editRuleTable}
                      textSearch={ruleChanges.textSearch}
                    />
                  </StyledRowDiv>
                </ExpandCollapseDetailSection>
              )}
              {!isGroupRule() ? (
                <DestinationRules
                  ruleId={ruleId}
                  ruleChanges={ruleChanges}
                  setRuleChanges={setRuleChanges}
                  collapsedState={collapsedState}
                  setCollapsedState={setCollapsedState}
                />
              ) : (
                <></>
              )}
              {ruleId && (
                <ExpandCollapseDetailSection
                  sectionTitle={`Change History`}
                  collapsedState={collapsedState}
                  setCollapsedState={setCollapsedState}
                  helpLink="/Processing-Rules/Rule-Screen&anchor=change-history"
                >
                  <StyledRowDiv className="row">
                    <RuleChangeHistory
                      changeHistoryRecords={ruleChanges.changeHistoryRecords}
                    />
                  </StyledRowDiv>
                </ExpandCollapseDetailSection>
              )}
            </div>
          </>
        )}
      </form>
      <RuleSetDatesDialog
        showDateModal={showDateModal}
        ruleChanges={ruleChanges}
        setRuleChanges={setRuleChanges}
        itemEffectiveDate={itemEffectiveDate}
        setItemEffectiveDate={setItemEffectiveDate}
        itemTerminationDate={itemTerminationDate}
        setItemTerminationDate={setItemTerminationDate}
        dateSetArray={dateSetArray}
        setDateSetArray={setDateSetArray}
        dateSetId={dateSetId}
        setDateSetId={setDateSetId}
        onCloseModal={() => setShowDateModal(false)}
      />
    </Authorize>
  );
}

export default Rule;
