import React from "react";
import _ from "lodash";
import TextInput from "../../common/input/TextInput";
import SelectInput from "../../common/input/SingleSelect";
import RuleEditorInput from "../../common/input/RuleEditorInput";
import { ruleConditionalOperators } from "../../../services/General";
import { NodeTypes } from "../rulemaps/RuleMapDataCommon";
import ExpandCollapse from "../../common/layout/ExpandCollapse";

function GroupRuleMapEditFieldDialogConditionFieldsSection({
  changes,
  isAdd,
  errors,
  onConditionChange,
  onConditionKeyTypeChange,
  onConditionOperatorChange,
  onConditionComparisonValueChange,
  onAddCondition,
  onRemoveCondition,
}) {
  const conditions = (changes.actionPrerequisites || []).filter(
    (r) => r.isAttachedByArtifact === false && r.condition === "IsCompared"
  );

  function getConditionOperatorList() {
    let opsList = ruleConditionalOperators.map((o) => {
      return {
        value: o.value,
        label: o.label,
        requiresValue: o.requiresValue,
      };
    });

    return opsList;
  }

  function getSelectedConditionOperator(condition) {
    const selected = {
      value: "-1",
      label: "(Select an Operator)",
      requiresValue: false,
    };
    const opList = getConditionOperatorList();

    if (!_.isEmpty(condition.comparison) && condition.comparison !== "-1") {
      selected.value = condition.comparison;
      const op = opList.find((r) => r.value === condition.comparison);
      if (op) {
        selected.label = op.label;
        selected.requiresValue = op.requiresValue;
      } else {
        selected.label = "Operator not found";
      }
    }

    return selected;
  }

  function getConditionSourceTypes() {
    const valueTypes = [];
    valueTypes.push({
      value: NodeTypes.RequestFieldValue.description,
      label: "Request Field Value",
    });
    valueTypes.push({
      value: NodeTypes.ResponseFieldValue.description,
      label: "Response Field Value",
    });
    valueTypes.push({
      value: NodeTypes.InternalVariable.description,
      label: "Internal Variable",
    });
    valueTypes.push({
      value: NodeTypes.EntityData.description,
      label: "Entity Data",
    });
    valueTypes.push({
      value: NodeTypes.GroupSetting.description,
      label: "Group Setting",
    });
    return valueTypes;
  }

  function getSelectedConditionSourceType(condition) {
    const selected = {
      value: "-1",
      label: "(Select a Key Type)",
    };
    const sourceTypes = getConditionSourceTypes();
    const desc = condition.sourceType?.description || condition.sourceType;

    if (!_.isEmpty(desc)) {
      selected.value = desc;
      selected.label =
        sourceTypes.find((r) => r.value === desc)?.label ??
        "Key Type not found";
    }

    return selected;
  }

  function getConditionComparisonValue(condition) {
    let value = "";

    if ((condition.comparisonValues || []).length > 0) {
      value = condition.comparisonValues[0];
    }

    return value;
  }

  const hasWarning =
    (changes.type === NodeTypes.Rejection && conditions.length < 1) ||
    errors.conditionSourceType ||
    errors.conditionSourceKey ||
    errors.conditionComparison ||
    errors.conditionComparisonValues;

  if (conditions.length < 1)
    return (
      <>
        <ExpandCollapse
          id="groupRuleDialogCondition"
          title="Condition"
          subTitle={
            hasWarning ? (
              <span
                className="material-icons"
                style={{ paddingLeft: "5px", color: "var(--notify-warning)" }}
              >
                warning
              </span>
            ) : (
              ""
            )
          }
          defaultState={isAdd}
        >
          {changes.type === NodeTypes.Rejection && conditions.length < 1 && (
            <div
              className="flex-row-with-wrap"
              style={{ marginBottom: "20px" }}
            >
              <span
                className="material-icons"
                style={{ paddingRight: "5px", color: "var(--notify-warning)" }}
              >
                warning
              </span>{" "}
              <b>Warning:</b>
              <div
                style={{
                  borderLeft: "4px solid var(--notify-warning)",
                  paddingLeft: "4px",
                }}
              >
                Included Rejections must have a condition
              </div>
            </div>
          )}
          <div className="flex-row-with-wrap-and-justify">
            <i>No condition has been added</i>
            <button
              type="button"
              className="btn btn-secondary btn-with-icon"
              onClick={onAddCondition}
            >
              <span className="material-symbols-outlined">add</span>
              Add Condition
            </button>
          </div>
        </ExpandCollapse>
      </>
    );
  const condition = conditions[0];
  const selectedOperator = getSelectedConditionOperator(condition);

  // We are only supporting a single condition right now, so show warning if more than one but allow first to be edited.
  return (
    <>
      <br />
      <ExpandCollapse
        id="groupRuleDialogCondition"
        title="Condition"
        defaultState={isAdd}
      >
        {conditions.length > 1 && (
          <div className="flex-row-with-wrap">
            <span
              style={{ color: "var(--notify-danger)" }}
              className="material-icons"
            >
              warning
            </span>
            <strong style={{ color: "var(--notify-danger)" }}>
              Warning:&nbsp;&nbsp;
            </strong>
            This field has more than one attached condition which is not
            supported at this time. Convert these to a single condition using
            the Value script.
          </div>
        )}
        <div className="flex-row-with-wrap-and-justify">
          <i>&nbsp;</i>
          <button
            type="button"
            className="btn btn-secondary btn-with-icon"
            onClick={onRemoveCondition}
          >
            <span className="material-symbols-outlined">delete</span> Remove
            Condition
          </button>
        </div>
        <SelectInput
          id="sourceType"
          name="sourceType"
          label="Key Type"
          options={getConditionSourceTypes()}
          value={getSelectedConditionSourceType(condition)}
          onChange={(option) =>
            onConditionKeyTypeChange(condition.identifier, option)
          }
          placeholder=""
          error={errors.conditionSourceType}
        />
        <TextInput
          id="sourceKey"
          label="Item Key"
          onChange={({ target }) =>
            onConditionChange(condition.identifier, target)
          }
          placeholder="Item Key"
          name="sourceKey"
          value={condition.sourceKey}
          error={errors.conditionSourceKey}
        />
        <SelectInput
          id="comparison"
          name="comparison"
          label="Operator"
          options={getConditionOperatorList()}
          value={selectedOperator}
          onChange={(option) =>
            onConditionOperatorChange(condition.identifier, option)
          }
          placeholder=""
          error={errors.conditionComparison}
        />
        {selectedOperator?.requiresValue === true && (
          <RuleEditorInput
            id="comparisonValues"
            label="Value"
            onChange={({ target }) =>
              onConditionComparisonValueChange(
                condition.identifier,
                target.value
              )
            }
            placeholder="Value"
            name="comparisonValues"
            value={getConditionComparisonValue(condition)}
            error={errors.conditionComparisonValues}
          />
        )}
      </ExpandCollapse>
    </>
  );
}

export default GroupRuleMapEditFieldDialogConditionFieldsSection;
