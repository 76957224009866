import React from "react";
import _ from "lodash";
import TextInput from "../../common/input/TextInput";
import { NodeTypes } from "../rulemaps/RuleMapDataCommon";
import SelectInput from "../../common/input/SingleSelect";
import RuleEditorInput from "../../common/input/RuleEditorInput";
import { rejectionLevelTypes } from "../../../services/General";

function GroupRuleMapEditFieldDialogValueFields({
  changes,
  errors,
  onChange,
  onValueTypeChange,
  onRejectionLevelChange,
}) {
  function getIncludedDataValueTypes() {
    const valueTypes = [];
    valueTypes.push({
      value: NodeTypes.RequestFieldValue.description,
      label: "Request Field Value",
    });
    valueTypes.push({
      value: NodeTypes.ResponseFieldValue.description,
      label: "Response Field Value",
    });
    valueTypes.push({
      value: NodeTypes.InternalVariable.description,
      label: "Internal Variable",
    });
    valueTypes.push({
      value: NodeTypes.TransmissionMessage.description,
      label: "Transmission Message",
    });
    valueTypes.push({
      value: NodeTypes.TransactionMessage.description,
      label: "Transaction Message",
    });
    return valueTypes;
  }

  function getSelectedIncludedDataValueType() {
    const selected = {
      value: "-1",
      label: "(Select a Value Type)",
    };
    const valueTypes = getIncludedDataValueTypes();

    if (!_.isEmpty(changes.valueType) && changes.valueType !== "-1") {
      selected.value = changes.valueType;
      selected.label =
        valueTypes.find((r) => r.value === changes.valueType)?.label ??
        "Value Type not found";
    }

    return selected;
  }

  function getSelectedRejectionLevel() {
    const selected = {
      value: "-1",
      label: "(Select a Level)",
    };

    if (!_.isEmpty(changes.level) && changes.level !== "-1") {
      selected.value = changes.level;
      selected.label =
        rejectionLevelTypes.find((r) => r.value === changes.level)?.label ??
        "Level not found";
    }

    return selected;
  }

  return (
    <>
      {changes.type === NodeTypes.InternalVariable ? (
        <>
          <SelectInput
            id="valueType"
            name="valueType"
            label="Value Type"
            options={getIncludedDataValueTypes()}
            value={getSelectedIncludedDataValueType()}
            onChange={onValueTypeChange}
            placeholder=""
            error={errors.valueType}
          />
          <RuleEditorInput
            id="itemValue"
            label="Item Value"
            onChange={onChange}
            placeholder="Item Value"
            name="itemValue"
            value={changes.itemValue}
            error={errors.itemValue}
          />
        </>
      ) : (
        <>
          <TextInput
            id="itemValue"
            label="Item Value"
            onChange={onChange}
            placeholder="Item Value"
            name="itemValue"
            value={changes.itemValue}
            error={errors.itemValue}
          />
        </>
      )}
      {changes.type === NodeTypes.Rejection && (
        <SelectInput
          id="level"
          name="level"
          label="Level"
          options={[...rejectionLevelTypes]}
          value={getSelectedRejectionLevel()}
          onChange={onRejectionLevelChange}
          placeholder=""
          error={errors.level}
        />
      )}
    </>
  );
}

export default GroupRuleMapEditFieldDialogValueFields;
