import React, { useEffect, useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import {
  getBadgeColorsFromType,
  NodeTypes,
} from "../rulemaps/RuleMapDataCommon";
import { StyledCharblockDiv } from "../rulemaps/RuleMapCommonStyles";
import { emptyRuleMapField } from "../../../viewmodels/groupRuleMapsVm";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useGroupRuleMaps } from "../../../contexts/GroupRuleMapsContext";
import ExpandCollapse from "../../common/layout/ExpandCollapse";
import { useMobile } from "../../../hooks/useMobile";
import useApi from "../../../hooks/useApi";
import { apiLoadRuleTableDefinition } from "../../../api/RuleTableDefinitionApi";
import { useRuleTableDefinitions } from "../../../contexts/RuleTableDefinitionsContext";
import GroupRuleMapRuleTableEdit from "./GroupRuleMapRuleTableEdit";
import { StyledBackButtonDiv } from "../../common/layout/CommonStyledControls";

function GroupRuleMapNewFieldToolbar({
  ruleMap,
  onSaveRuleTableDataChangesToRuleMap,
  onSaveRuleTableDataScheduleChangesToRuleMap,
  onDeleteRuleTableDataFromRuleMap,
}) {
  const { isMobileSize } = useMobile();
  const { groupRuleMapsData, setGroupRuleMapsData } = useGroupRuleMaps();
  const { ruleTableDefinitionsData, setRuleTableDefinitionsData } =
    useRuleTableDefinitions();
  const { loading: loadingRtd, api: apiLoadRuleTableDef } = useApi(
    apiLoadRuleTableDefinition
  );
  const [ruleTables, setRuleTables] = useState([]);
  const [selectedRuleTable, setSelectedRuleTable] = useState(null);

  const variableColors = getBadgeColorsFromType(NodeTypes.InternalVariable);
  const rejectionColors = getBadgeColorsFromType(NodeTypes.Rejection);
  const ruleTableColors = getBadgeColorsFromType(NodeTypes.LookupTable);

  useEffect(() => {
    // Sort and save rule tables for selection
    if (ruleMap && ruleMap.ruleTables) {
      let rts = (ruleMap.ruleTables || []).sort((a, b) =>
        a.ruleTableName > b.ruleTableName ? 1 : -1
      );
      // De-dup this array by name since we want one entry per name
      rts = _.uniqBy(rts, "ruleTableName");
      setRuleTables(rts);
    }
  }, []);

  async function getRuleTableDefinition(rtdId) {
    let ruleTableDefinitionList =
      ruleTableDefinitionsData.ruleTableDefinitionListCache || [];

    // Do we already have this rule table definition in the cache? If so, use it.
    let ruleTableDefinition = ruleTableDefinitionList.find(
      (r) => r.id === rtdId
    );
    if (ruleTableDefinition) return ruleTableDefinition;

    // Rule table definition is not in the cache, so load it and update the cache.
    ruleTableDefinition = await loadRuleTableDefinition(rtdId);
    addRuleTableDefinitionToCache(ruleTableDefinition);
    return ruleTableDefinition;
  }

  async function loadRuleTableDefinition(rtdId) {
    return await apiLoadRuleTableDef.call(rtdId, async (result) => {
      return result;
    });
  }

  function addRuleTableDefinitionToCache(ruleTableDefinition) {
    const ruleTableDefinitionList =
      ruleTableDefinitionsData.ruleTableDefinitionListCache || [];

    const rtdList = [...ruleTableDefinitionList];
    rtdList.push(ruleTableDefinition);

    setRuleTableDefinitionsData({
      type: ContextProviderActions.setRuleTableDefinitionListCache,
      payload: rtdList,
    });
  }

  function onAddButtonClick(e, type) {
    if (e) e.preventDefault();

    const editItem = { ...emptyRuleMapField, isNew: true, type: type };
    const editFields = [...groupRuleMapsData.editFields];
    editFields.push(editItem);

    setGroupRuleMapsData({
      type: ContextProviderActions.setGroupRuleMapEditNode,
      payload: editFields,
    });
  }

  async function onRuleTableButtonClick(e, ruleTable) {
    if (e) e.preventDefault();

    const rtd = await getRuleTableDefinition(ruleTable.ruleTableDefinitionId);
    ruleTable.ruleTableDefinition = rtd;
    setSelectedRuleTable(ruleTable);
  }

  function getToolbarButton(shortText, longText, type, colors) {
    const btn = (
      <div>
        <StyledToolbarButton
          colors={colors}
          type="button"
          className="btn btn-secondary"
          onClick={(e) => onAddButtonClick(e, type)}
          title={"Add " + longText}
        >
          <StyledCharblockDiv
            className="charblock"
            colors={colors}
            style={{ marginRight: "8px", paddingRight: "5px" }}
          >
            {shortText}
          </StyledCharblockDiv>
          {longText}
        </StyledToolbarButton>
      </div>
    );

    return btn;
  }

  function getRuleTableButton(ruleTable) {
    const btn = (
      <div>
        <StyledToolbarButton
          colors={ruleTableColors}
          type="button"
          className="btn btn-secondary"
          onClick={(e) => onRuleTableButtonClick(e, ruleTable)}
          title={"Update data for rule table " + ruleTable.ruleTableName}
        >
          <StyledCharblockDiv
            className="charblock"
            colors={ruleTableColors}
            style={{ marginRight: "8px", paddingRight: "5px" }}
          >
            RT
          </StyledCharblockDiv>
          {ruleTable.ruleTableName}
          <span className="material-icons" style={{ marginLeft: "auto" }}>
            chevron_right
          </span>
        </StyledToolbarButton>
      </div>
    );

    return btn;
  }

  function setDataSearchChanges(search) {
    setGroupRuleMapsData({
      type: ContextProviderActions.saveGroupRuleMapRuleDataSearch,
      payload: search,
    });
  }

  function getSelectedRuleTableRecords() {
    if (selectedRuleTable === null) return [];
    const records = (ruleMap.ruleTableRows || []).filter(
      (r) => r.ruleTableName === selectedRuleTable.ruleTableName
    );
    return records;
  }

  function getSelectedRuleTableColumns() {
    if (selectedRuleTable === null) return [];
    const columns = selectedRuleTable.ruleTableDefinition?.columns || [];
    return columns;
  }

  function getSelectedRuleTableColumnCount() {
    const columns = getSelectedRuleTableColumns();
    return columns.length;
  }

  return (
    <div
      style={{
        padding: isMobileSize ? "20px 0 0 0" : "0 20px 20px 0",
        minWidth: isMobileSize ? "350px" : "414px",
        width: isMobileSize ? "350px" : "414px",
      }}
    >
      <StyledEditToolbar>
        <ExpandCollapse
          id="groupRuleAddNewField"
          title="Add new field:"
          defaultState={false}
        >
          <StyledEditToolbarContents>
            {getToolbarButton(
              "VAR",
              "Variable",
              NodeTypes.InternalVariable,
              variableColors
            )}
            {getToolbarButton(
              "REJ",
              "Included Rejection",
              NodeTypes.Rejection,
              rejectionColors
            )}
            {getToolbarButton(
              "REJ",
              "Excluded Rejection",
              NodeTypes.ExcludedRejection,
              rejectionColors
            )}
          </StyledEditToolbarContents>
        </ExpandCollapse>
        <ExpandCollapse
          id="groupRuleUpdateRuleTableData"
          title="Update rule table data:"
          defaultState={false}
        >
          {loadingRtd ? (
            <p style={{ fontStyle: "italic" }}>
              Loading rule table definition...
            </p>
          ) : (
            <>
              {selectedRuleTable !== null ? (
                <>
                  <StyledBackButtonDiv style={{ marginTop: "20px" }}>
                    <button
                      title="Return to rule table list"
                      type="button"
                      className="btn btn-link"
                      onClick={() => setSelectedRuleTable(null)}
                    >
                      <i className="fa fa-angle-left"></i> Back to rule tables
                    </button>
                  </StyledBackButtonDiv>
                  <GroupRuleMapRuleTableEdit
                    ruleMap={ruleMap}
                    ruleTableId={selectedRuleTable.ruleTableId}
                    columns={getSelectedRuleTableColumns()}
                    records={getSelectedRuleTableRecords()}
                    colCount={getSelectedRuleTableColumnCount}
                    search={groupRuleMapsData.dataSearch}
                    setSearch={setDataSearchChanges}
                    onSaveRuleTableDataChangesToRuleMap={
                      onSaveRuleTableDataChangesToRuleMap
                    }
                    onSaveRuleTableDataScheduleChangesToRuleMap={
                      onSaveRuleTableDataScheduleChangesToRuleMap
                    }
                    onDeleteRuleTableDataFromRuleMap={
                      onDeleteRuleTableDataFromRuleMap
                    }
                  />
                </>
              ) : (
                <StyledEditToolbarContents>
                  {ruleTables.map((rt, idx) => (
                    <span key={`rt-${idx}`}>{getRuleTableButton(rt)}</span>
                  ))}
                </StyledEditToolbarContents>
              )}
            </>
          )}
        </ExpandCollapse>
      </StyledEditToolbar>
    </div>
  );
}

const StyledEditToolbar = styled.div`
  padding: 0 10px 10px 10px;
  background: transparent;
  border: 1px solid var(--button-secondary-border);
  border-radius: 3px;
`;

const StyledEditToolbarContents = styled.div`
  display: block;
  padding: 0 20px;
`;

const StyledToolbarButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  border-color: ${(props) => props.colors.bgColor};
`;

export default GroupRuleMapNewFieldToolbar;
