import React from "react";
import styled from "styled-components";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useUserPreferences } from "../../../contexts/UserPreferencesContext";

function ExpandCollapse({
  id,
  title,
  subTitle = "",
  defaultState = true,
  children,
}) {
  const { userPrefs, setUserPrefs } = useUserPreferences();

  let expanded = defaultState;
  const o = userPrefs.expandCollapseState.find((o) => o.id === id);
  if (o) expanded = o.value;

  return (
    <>
      <ExpanderDiv
        onClick={() => {
          setUserPrefs({
            type: ContextProviderActions.setExpandCollapseState,
            payload: { id, expanded: !expanded },
          });
        }}
      >
        <h3 className="flex-row-with-wrap">
          {title} {subTitle}
        </h3>
        <i
          title={expanded ? "Collapse" : "Expand"}
          className={
            expanded
              ? "fa fa-solid fa-caret-down"
              : "fa fa-solid fa-caret-right"
          }
        ></i>
      </ExpanderDiv>
      <ContentDiv>{expanded ? children : <></>}</ContentDiv>
    </>
  );
}

const ExpanderDiv = styled.div`
  border-bottom: 1px solid #888;
  color: #888;
  display: flex;
  align-items: center;
  padding: 0 2px;
  margin: 20px 5px 10px 0;
  cursor: pointer;

  @media only screen and (max-width: 767px) {
    margin-left: -10px;
  }

  > h3 {
    font-size: 18px;
    font-weight: normal;
    padding: 0;
    margin: 0;
  }

  > i {
    font-size: 30px;
    margin-left: auto;
    cursor: pointer;
  }
`;

const ContentDiv = styled.div`
  margin: 0 15px;

  @media only screen and (max-width: 767px) {
    margin: 0;
  }
`;

export default ExpandCollapse;
