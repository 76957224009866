import React from "react";
import _ from "lodash";
import { useMobile } from "../../../hooks/useMobile";

function ToggleButtonGroupInput({
  name,
  id,
  value,
  options,
  label,
  error,
  onChange,
  labelStyle,
  showLabelInline = false,
  disabled = false,
  controlClass = "",
  buttonClass = "",
  customWidth = "",
}) {
  const { isMobileSize } = useMobile();

  let wrapperClass = !_.isEmpty(controlClass) ? controlClass : "form-group";
  if (error && error.length > 0) {
    wrapperClass += " has-error";
  }

  let buttonWidth = isMobileSize ? "80px" : "136px";
  if (!_.isEmpty(customWidth)) buttonWidth = customWidth;

  let btnClass = "btn";
  if (!_.isEmpty(buttonClass)) btnClass = buttonClass;

  let labelStyles = { ...(labelStyle || {}) };
  if (showLabelInline) {
    labelStyles = { ...labelStyles, marginRight: "10px" };
  }
  if (disabled === true) {
    labelStyles = { ...labelStyles, color: "var(--text-disabled)" };
  }

  return (
    <div className={wrapperClass}>
      {!showLabelInline && (
        <label htmlFor={id} style={labelStyles} disabled={disabled}>
          {label}
        </label>
      )}
      <div className="field">
        {showLabelInline && (
          <label htmlFor={id} style={labelStyles}>
            {label}
          </label>
        )}
        <div id={id} name={name} role="group" className="btn-group">
          {options.map((option) => (
            <button
              key={option.value}
              type="button"
              value={option.value}
              disabled={disabled}
              className={
                option.value === value
                  ? `${btnClass} btn-primary`
                  : `${btnClass} btn-secondary`
              }
              style={{
                width: buttonWidth,
                maxWidth: buttonWidth,
                minWidth: buttonWidth,
              }}
              onClick={() => onChange(option.value)}
            >
              {option.label}
            </button>
          ))}
        </div>
      </div>

      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
}

export default ToggleButtonGroupInput;
