import React from "react";
import TextInput from "../../common/input/TextInput";

function GroupRuleMapEditFieldDialogKeyFields({ changes, errors, onChange }) {
  return (
    <>
      <TextInput
        id="identifier"
        label="Identifier"
        onChange={onChange}
        placeholder="Identifier"
        name="identifier"
        autoFocus={true}
        value={changes.identifier}
        error={errors.identifier}
      />
      <TextInput
        id="itemKey"
        label="Item Key"
        onChange={onChange}
        placeholder="Item Key"
        name="itemKey"
        value={changes.itemKey}
        error={errors.itemKey}
      />
    </>
  );
}

export default GroupRuleMapEditFieldDialogKeyFields;
