import React from "react";
import TextInput from "../../common/input/TextInput";
import ToggleButtonGroupInput from "../../common/input/ToggleButtonGroupInput";
import CreatableSelectInput from "../../common/input/CreatableSelectInput";

function GroupRuleMapEditFieldDialogFlagModuleFields({
  attachType,
  changes,
  errors,
  moduleOptions,
  onChange,
  onAttachTypeChange,
  onModuleChange,
}) {
  function getSelectedModule() {
    const selected = {
      value: "-1",
      label: "(Select or enter a module...)",
    };

    if ((changes.modules || []).length > 0) {
      selected.value = changes.modules[0].name;
      selected.label = selected.value;
    }

    return selected;
  }

  return (
    <>
      <ToggleButtonGroupInput
        id="isFlagType"
        name="isFlagType"
        label="Attach Type"
        options={[
          { value: "Flag", label: "Flag" },
          { value: "Module", label: "Module" },
        ]}
        value={attachType}
        onChange={onAttachTypeChange}
      />
      {attachType === "Flag" && (
        <TextInput
          id="flagName"
          label="Flag"
          onChange={onChange}
          placeholder="Flag"
          name="flagName"
          value={changes.flagName || ""}
          error={errors.flagName}
        />
      )}
      {attachType === "Module" && (
        <>
          <CreatableSelectInput
            id="module"
            name="module"
            label="Module"
            options={moduleOptions}
            value={getSelectedModule()}
            onChange={onModuleChange}
            placeholder="Select or enter a module..."
            error={errors.module}
          />
        </>
      )}
    </>
  );
}

export default GroupRuleMapEditFieldDialogFlagModuleFields;
