import React, { useEffect, useState } from "react";
import _ from "lodash";
import RuleMapLayouter from "../rulemaps/RuleMapLayouter";
import { useAuth } from "../../../contexts/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import {
  apiLoadGroupRuleMap,
  apiSaveGroupRuleMap,
} from "../../../api/GroupRuleMapApi";
import useApi from "../../../hooks/useApi";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import {
  notifyError,
  notifySuccess,
  notifyWarn,
} from "../../../services/NotificationService";
import Spinner from "../../common/ui/Spinner";
import Authorize from "../../common/layout/Authorize";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { useMobile } from "../../../hooks/useMobile";
import {
  StyledBackButtonDiv,
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledNoResultsDiv,
  StyledScreenHelpWithBackDiv,
} from "../../common/layout/CommonStyledControls";
import HelpLink from "../../common/ui/HelpLink";
import { transformRuleMapToIncludeModules } from "../rulemaps/RuleMapDataModuleExtractor";
import { transformRuleMap } from "../rulemaps/RuleMapDataTransformer";
import { getReactFlowNodesAndEdgesForGroup } from "../rulemaps/RuleMapDataToFlowConverter";
import { useRuleMaps } from "../../../contexts/RuleMapsContext";
import {
  emptyGroupRuleMapFilter,
  emptyRuleMapField,
} from "../../../viewmodels/groupRuleMapsVm";
import GridFreeFormSearchBar from "../../common/grid/GridFreeFormSearchBar";
import {
  buildUniqueIdForNode,
  extractNodeKeyAndTypeFromId,
  getNodeForGroupRuleMapById,
  getRuleMapArrayForNodeType,
  NodeTypes,
} from "../rulemaps/RuleMapDataCommon";
import { useGroupRuleMaps } from "../../../contexts/GroupRuleMapsContext";
import GroupRuleMapSidebar from "./GroupRuleMapSidebar";
import { parseISO } from "date-fns";
import { generateUUID, yyyy_mm_dd } from "../../../services/General";
import ConfirmDialog from "../../dialogs/ConfirmDialog";

function GroupRuleMap() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { isMobileSize, isTabletSize } = useMobile();
  const [windowSizeW, windowSizeH] = useWindowSize([800, 800]);
  const { ruleMapsData, setRuleMapsData } = useRuleMaps();
  const { groupRuleMapsData, setGroupRuleMapsData } = useGroupRuleMaps();
  const { loading, api: apiLoad } = useApi(apiLoadGroupRuleMap);
  const { loading: saving, api: apiSave } = useApi(apiSaveGroupRuleMap);
  const [showPossiblePaths, setShowPossiblePaths] = useState(true);
  const [showModules, setShowModules] = useState(false);
  const [isModuleViewMode, setIsModuleViewMode] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [originalRuleMap, setOriginalRuleMap] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);

  const resId = params && params.groupId;

  // Remove the resizeObserver error
  //   jon, 8/23/24: This is a workaround to remove the specific errors below. These errors only display in a dev environment and
  //   are benign according to many stack overflow articles. Here is a discussion about it on the react flow github:
  //   https://github.com/xyflow/xyflow/issues/3076
  useEffect(() => {
    const errorHandler = (e) => {
      if (
        e.message.includes(
          "ResizeObserver loop completed with undelivered notifications" ||
            "ResizeObserver loop limit exceeded"
        )
      ) {
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.style.display = "none";
        }
      }
    };
    window.addEventListener("error", errorHandler);

    return () => {
      window.removeEventListener("error", errorHandler);
    };
  });

  useEffect(() => {
    // When the screen gets unloaded, reset the context state so nothing is remembered between loads of a rule map
    return () => {
      setRuleMapsData({
        type: ContextProviderActions.loadRuleMapForClaim,
        payload: null,
      });
      setGroupRuleMapsData({
        type: ContextProviderActions.resetGroupRuleMap,
        payload: null,
      });
    };
  }, []);

  useEffect(() => {
    if (auth.authenticated) {
      // Reset screen entity when id parameter changes
      setRuleMapsData({
        type: ContextProviderActions.loadRuleMapForClaim,
        payload: null,
      });
      setGroupRuleMapsData({
        type: ContextProviderActions.resetGroupRuleMap,
        payload: null,
      });

      loadRuleMap();
    }
  }, [auth.authenticated, params?.groupId]);

  useEffect(() => {
    if (
      ruleMapsData &&
      ruleMapsData.ruleMap !== null &&
      showPossiblePaths !== ruleMapsData.showPossiblePaths
    ) {
      handlePossiblePathsClick(ruleMapsData.showPossiblePaths);
    }
  }, [ruleMapsData?.showPossiblePaths]);

  useEffect(() => {
    if (
      ruleMapsData &&
      ruleMapsData.ruleMap !== null &&
      showModules !== ruleMapsData.showModules
    ) {
      handleShowModulesClick(ruleMapsData.showModules);
    }
  }, [ruleMapsData?.showModules]);

  useEffect(() => {
    if (
      ruleMapsData &&
      ruleMapsData.ruleMap !== null &&
      isModuleViewMode !== ruleMapsData.isModuleViewMode
    ) {
      handleModuleViewChange(ruleMapsData.isModuleViewMode);
    }
  }, [ruleMapsData?.isModuleViewMode]);

  useEffect(() => {
    if (ruleMapsData && ruleMapsData.ruleMap && ruleMapsData.ruleMap !== null) {
      rebuildNodeAndEdges(
        ruleMapsData.ruleMap,
        -1,
        ruleMapsData.showModules,
        ruleMapsData.selectedField
      );
    }
  }, [ruleMapsData.selectedField]);

  useEffect(() => {
    if (windowSizeH === 0 || windowSizeW === 0) return;

    const direction =
      isMobileSize || isTabletSize || windowSizeH > windowSizeW ? "TB" : "LR";

    if (ruleMapsData && direction !== ruleMapsData.layoutDirection) {
      setRuleMapsData({
        type: ContextProviderActions.setRuleMapLayoutDirectionWithoutRefresh,
        payload: direction,
      });
    }
  }, [windowSizeH, windowSizeW, isMobileSize, isTabletSize]);

  // When the rule map changes in any way, compare it to the original to see if we have unsaved changes
  //   Note that this won't detect field changes that are made and then undone because of custom properties
  //   that get added on saving of the field.
  useEffect(() => {
    if (ruleMapsData && ruleMapsData.ruleMap && ruleMapsData.ruleMap !== null) {
      const originalRuleMapJson = JSON.stringify(originalRuleMap);
      const ruleMapJson = JSON.stringify(ruleMapsData.ruleMap);
      setHasUnsavedChanges(originalRuleMapJson !== ruleMapJson);
    }
  }, [ruleMapsData?.ruleMap]);

  async function loadRuleMap() {
    apiLoad.call(resId, (result) => {
      let vm = null;
      if (!result) {
        notifyError(
          `No rule map for group ${resId} was found. It may still be generating if the group or rule is new.`
        );
        return;
      } else {
        vm = result;
      }

      setOriginalRuleMap(vm);
      doPostLoadRuleMap(vm);
    });
  }

  function doPostLoadRuleMap(vm) {
    setRuleMapsData({
      type: ContextProviderActions.loadRuleMapForGroup,
      payload: vm,
    });

    rebuildNodeAndEdges(vm, -1, false, ruleMapsData.selectedField);
  }

  function handlePossiblePathsClick(showPaths) {
    setShowPossiblePaths(showPaths);
    rebuildNodesAndRefresh(showPaths);
  }

  function handleShowModulesClick(showModulesSetting) {
    setShowModules(showModulesSetting);
    rebuildNodesAndRefresh(showPossiblePaths);
  }

  function handleModuleViewChange(moduleViewSetting) {
    setIsModuleViewMode(moduleViewSetting);
    rebuildNodesAndRefresh(showPossiblePaths);
  }

  function getGroupSetting(groupSettings, key) {
    let value = "";

    const gs = groupSettings.find((g) => g.key === key);
    if (gs) {
      value = gs.value;
    }

    return value;
  }

  function injectGroupSettingsToFieldList(ruleMap, fieldList) {
    const groupSettings = ruleMap.groupSettings || [];
    const gsFields = fieldList.filter((f) => f.type === NodeTypes.GroupSetting);

    for (let i = 0; i < gsFields.length; i++) {
      gsFields[i].value = getGroupSetting(groupSettings, gsFields[i].name);
    }

    return fieldList;
  }

  function rebuildNodesAndRefresh(showPaths) {
    rebuildNodeAndEdges(
      ruleMapsData.ruleMap,
      -1,
      ruleMapsData.showModules,
      ruleMapsData.selectedField
    );

    window.setTimeout(() => {
      window.requestAnimationFrame(() => {
        setRuleMapsData({
          type: ContextProviderActions.doRuleMapRefreshLayout,
          payload: null,
        });
      });
    }, 100);
  }

  function rebuildNodeAndEdges(
    ruleMap,
    newTransactionNum,
    newShowModules,
    selectedField
  ) {
    const rm = _.cloneDeep(ruleMap);

    const selectedModuleId =
      ruleMapsData?.isModuleViewMode === true &&
      ruleMapsData?.selectedModuleId !== "-1"
        ? ruleMapsData.selectedModuleId
        : "-1";

    const shouldShowModules =
      ruleMapsData?.isModuleViewMode === true ? false : newShowModules;

    const transformedFieldLists = transformRuleMap(
      rm,
      newTransactionNum,
      shouldShowModules
    );
    let fieldList = transformedFieldLists.fieldList;

    fieldList = injectGroupSettingsToFieldList(rm, fieldList);

    fieldList = transformRuleMapToIncludeModules(
      fieldList,
      shouldShowModules,
      selectedModuleId,
      selectedField
    );

    const newNodesAndEdges = getReactFlowNodesAndEdgesForGroup(
      fieldList,
      selectedModuleId,
      selectedField
    );

    setNodes(newNodesAndEdges.nodeList);
    setEdges(newNodesAndEdges.edgeList);

    window.setTimeout(() => {
      setRefreshing(false);
    }, 100);
  }

  function setNodes(nodes) {
    setRuleMapsData({
      type: ContextProviderActions.setRuleMapNodes,
      payload: nodes,
    });
  }

  function setEdges(edges) {
    setRuleMapsData({
      type: ContextProviderActions.setRuleMapEdges,
      payload: edges,
    });
  }

  function refreshNodesAndEdges() {
    setRefreshing(true);

    window.setTimeout(() => {
      window.requestAnimationFrame(() => {
        rebuildNodeAndEdges(
          ruleMapsData.ruleMap,
          -1,
          ruleMapsData.showModules,
          ruleMapsData.selectedField
        );
      });
    }, 100);
  }

  function setSearchChanges(search) {
    setRuleMapsData({
      type: ContextProviderActions.saveGroupRuleMapFilter,
      payload: search,
    });
  }

  async function handleReset() {
    setSearchChanges({
      ...emptyGroupRuleMapFilter,
    });
    setRuleMapsData({
      type: ContextProviderActions.resetRuleMapSelections,
      payload: null,
    });
    setGroupRuleMapsData({
      type: ContextProviderActions.setGroupRuleMapIsFilterSet,
      payload: false,
    });
  }

  async function onSubmit(event, newSearch) {
    if (event) event.preventDefault();

    var updatedSearch = {
      ...ruleMapsData.search,
      ...newSearch,
    };
    setSearchChanges(updatedSearch);

    refreshNodesAndEdges();
  }

  function scrollIntoView(element) {
    window.setTimeout(() => {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }, 250);
  }

  function handleFilterItemClick(value, type) {
    const option = {
      value: value,
      type: type,
    };

    setRuleMapsData({
      type: ContextProviderActions.selectRuleMapField,
      payload: { selectedField: option },
    });

    setGroupRuleMapsData({
      type: ContextProviderActions.setGroupRuleMapIsFilterSet,
      payload: true,
    });

    const element = document.getElementById("content-div");
    if (element) {
      scrollIntoView(element);
    }
  }

  function getNumberOfSetFilters() {
    let numFilters = 0;

    // Check for any unselected rules
    const ruleSelectionLength = (
      ruleMapsData.search.ruleSelections || []
    ).filter((r) => r.checked === false).length;

    if (ruleSelectionLength > 0) numFilters++;

    // Check for a filter date other than today
    const today = parseISO(yyyy_mm_dd(new Date())).toISOString();
    const filterDate = ruleMapsData.search.filterDate.toISOString();
    if (filterDate !== today) numFilters++;

    // Check if a field is selected for viewing in the rule map
    if ((ruleMapsData.selectedField?.value || "-1") !== "-1") numFilters++;

    return numFilters;
  }

  function setEditMode(edit) {
    setGroupRuleMapsData({
      type: ContextProviderActions.setGroupRuleMapIsEditMode,
      payload: edit,
    });
  }

  function handleEditModeClick(e) {
    if (e) e.preventDefault();

    setEditMode(true);
  }

  function formIsValid() {
    const _errors = {};

    // Any validation we can do at this level?
    return Object.keys(_errors).length === 0;
  }

  function handleSaveClick(e) {
    if (e) e.preventDefault();
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }

    setEditMode(false);
    saveGroupRuleMap();
  }

  async function saveGroupRuleMap() {
    var ruleMap = _.cloneDeep(ruleMapsData.ruleMap);

    apiSave.call({ id: resId, model: ruleMap }, (result) => {
      closeAllEditDialogs();
      if (!_.isEmpty(result)) {
        setOriginalRuleMap(result);
        doPostLoadRuleMap(result);
        notifySuccess(
          "Group Rule Map for group " + resId + " saved successfully"
        );
      } else {
        setOriginalRuleMap(ruleMap);
        doPostLoadRuleMap(ruleMap);
        notifyWarn(
          "Validation errors may have occurred. View the log entry in the error message for details."
        );
      }
    });
  }

  function handleSaveNodeChangesToRuleMap(editField) {
    var ruleMap = _.cloneDeep(ruleMapsData.ruleMap);
    let node;

    // Is this field new?
    if (editField.isAdd === true) {
      node = { ...emptyRuleMapField };
      // Attach this field to the proper array in the rulemap
      const array = getRuleMapArrayForNodeType(ruleMap, editField.type);
      array.push(node);
    } else {
      // Get the existing field from the rule map and merge the changes into it
      node = getNodeForGroupRuleMapById(ruleMap, editField.id);
    }
    Object.assign(node, editField);

    // Set this new rule map as our current version and the one that will be saved.
    doPostLoadRuleMap(ruleMap);
    notifySuccess("Field saved successfully");

    closeEditDialog(editField.id);

    // Reselect this field in the filter - this isn't quite right if Response field is selected a variable is edited - how to reselect the response field and then simulate click on actual node so we zoom in?
    let newId = buildUniqueIdForNode(editField, editField.type);
    if (editField.type === NodeTypes.Rejection) {
      newId = `ROOT-${newId}`;
    }
    handleFilterItemClick(newId, editField.type);
    return null;
  }

  function handleDeleteNodeFromRuleMap(editField) {
    console.log("Delete field", editField.id);

    var ruleMap = _.cloneDeep(ruleMapsData.ruleMap);

    const idInfo = extractNodeKeyAndTypeFromId(editField.id);
    const array = getRuleMapArrayForNodeType(ruleMap, idInfo.type) || [];

    if (array.length < 1) {
      console.log(
        `Array not found for type ${idInfo.type} in handleDeleteNodeFromRuleMap.`
      );
      return;
    }

    const index = array.findIndex(
      (i) =>
        i.displayKey === idInfo.key ||
        i.key === idInfo.key ||
        i.id === idInfo.key ||
        i.identifier === idInfo.key ||
        i.sourceKey === idInfo.key
    );
    if (index < 0) {
      console.log(
        `Key '${idInfo.key}' not found in handleDeleteNodeFromRuleMap.`
      );
      return;
    }

    array.splice(index, 1);
    doPostLoadRuleMap(ruleMap);
    notifySuccess("Field removed successfully");

    closeEditDialog(editField.id);
  }

  function addRowToUpdatedRuleTableRows(ruleMap, row) {
    // First remove any existing rule table rows with the same id since we only want the most recent.
    removeRowFromUpdatedRuleTableRows(ruleMap, row);

    // Add the row
    if (!ruleMap.updatedRuleTableRows) ruleMap.updatedRuleTableRows = [];
    ruleMap.updatedRuleTableRows.push(row);
  }

  function removeRowFromUpdatedRuleTableRows(ruleMap, row) {
    const index = (ruleMap.updatedRuleTableRows || []).findIndex(
      (r) => r.rowId === row.rowId
    );
    if (index >= 0) {
      ruleMap.updatedRuleTableRows.splice(index, 1);
    }
  }

  function handleSaveRuleTableDataChangesToRuleMap(
    id,
    ruleTableDataRow,
    inheritedRowId = ""
  ) {
    var ruleMap = _.cloneDeep(ruleMapsData.ruleMap);

    if (!_.isEmpty(id)) {
      // Existing row
      const row = ruleMap.ruleTableRows.find((r) => r.rowId === id);
      if (row) {
        Object.assign(row, ruleTableDataRow);
        addRowToUpdatedRuleTableRows(ruleMap, row);
      }
    } else {
      // New row
      ruleTableDataRow.id = generateUUID();
      ruleTableDataRow.rowId = ruleTableDataRow.id;

      // If this change was to an inherited data row, we just inserted a new row, but we also need to delete the old row (locally)
      //  from the rulemap for the UI.
      if (!_.isEmpty(inheritedRowId)) {
        const index = ruleMap.ruleTableRows.findIndex(
          (r) => r.rowId === inheritedRowId
        );
        if (index >= 0) {
          const overriddenRow = {
            ...ruleMap.ruleTableRows[index],
          };
          // Create the link back to the overridden row in case the user deletes this row before saving.
          ruleTableDataRow.overriddenRowId = overriddenRow.rowId;
          ruleTableDataRow.overriddenRuleId = overriddenRow.ruleId;

          // First add this old row to the updated array so we keep the inherited row on save
          addRowToUpdatedRuleTableRows(ruleMap, overriddenRow);
          ruleMap.ruleTableRows.splice(index, 1);
        }
      }

      ruleMap.ruleTableRows.push(ruleTableDataRow);
      addRowToUpdatedRuleTableRows(ruleMap, ruleTableDataRow);
    }

    doPostLoadRuleMap(ruleMap);
    notifySuccess("Rule table row updated successfully");
  }

  function handleSaveRuleTableDataScheduleChangesToRuleMap(
    id,
    ruleTableDataRow
  ) {
    var ruleMap = _.cloneDeep(ruleMapsData.ruleMap);

    // This was a new record that was created with a new effective date, so just insert it first
    ruleMap.ruleTableRows.push(ruleTableDataRow);
    addRowToUpdatedRuleTableRows(ruleMap, ruleTableDataRow);

    // Now, locate the existing row and update its termination date.
    const row = ruleMap.ruleTableRows.find(
      (r) => r.rowId === ruleTableDataRow.previousValueRowId
    );
    if (row) {
      // Update the existing row with a termination date equal to the new effective date.
      row.terminationDate = ruleTableDataRow.effectiveDate;
      addRowToUpdatedRuleTableRows(ruleMap, row); // This will delete the old row in updated array and add it back with changes
    }

    doPostLoadRuleMap(ruleMap);
    notifySuccess("Schedule change completed successfully");
  }

  function handleDeleteRuleTableDataFromRuleMap(id) {
    var ruleMap = _.cloneDeep(ruleMapsData.ruleMap);

    const index = ruleMap.ruleTableRows.findIndex((r) => r.rowId === id);
    if (index >= 0) {
      const rowToRemove = {
        ...ruleMap.ruleTableRows[index],
      };

      // Did the row we are about to remove override another row? If so, we need to add that one back to the main ruleTableRows array.
      if (!_.isEmpty(rowToRemove.overriddenRowId)) {
        const ovrIndex = ruleMap.updatedRuleTableRows.findIndex(
          (r) => r.rowId === rowToRemove.overriddenRowId
        );
        if (ovrIndex >= 0) {
          const overriddenRow = {
            ...ruleMap.updatedRuleTableRows[ovrIndex],
          };
          ruleMap.ruleTableRows.push(overriddenRow);
          removeRowFromUpdatedRuleTableRows(ruleMap, overriddenRow);
        }
      }

      removeRowFromUpdatedRuleTableRows(ruleMap, rowToRemove);
      ruleMap.ruleTableRows.splice(index, 1);
    }

    doPostLoadRuleMap(ruleMap);
    notifySuccess("Rule table row removed successfully");
  }

  function handleCancelClick(e) {
    if (e) e.preventDefault();

    if (hasUnsavedChanges) {
      setShowConfirmCancelModal(true);
    } else {
      doPostCancelOperations();
    }
  }

  function doPostCancelOperations() {
    setShowConfirmCancelModal(false);
    closeAllEditDialogs();
    doPostLoadRuleMap(originalRuleMap);
    setEditMode(false);
  }

  function closeEditDialog(id) {
    const editFields = [...groupRuleMapsData.editFields];
    const index = editFields.findIndex((r) => r.id === id);

    if (index >= 0) {
      editFields.splice(index, 1);
    }

    setGroupRuleMapsData({
      type: ContextProviderActions.setGroupRuleMapEditNode,
      payload: editFields,
    });
  }

  function closeAllEditDialogs() {
    setGroupRuleMapsData({
      type: ContextProviderActions.setGroupRuleMapEditNode,
      payload: [],
    });
  }

  const working = loading || saving || (ruleMapsData?.ruleMap || null) === null;

  return (
    <Authorize>
      <ConfirmDialog
        title="Confirm Cancel"
        question={`You have unsaved changes in this group rule map. These changes will be lost if you continue. Are you sure you wish to cancel?`}
        showModal={showConfirmCancelModal}
        onNo={() => setShowConfirmCancelModal(false)}
        onYes={doPostCancelOperations}
      />
      <StyledBackButtonDiv>
        <button
          title="Return to previous screen"
          type="button"
          className="btn btn-link"
          onClick={() => navigate(-1)}
        >
          <i className="fa fa-angle-left"></i> Back
        </button>
        <StyledScreenHelpWithBackDiv>
          <HelpLink path="/GroupRuleMap/GroupRule-Map-Screen" label="Help" />
        </StyledScreenHelpWithBackDiv>
      </StyledBackButtonDiv>
      <StyledHeaderRowDiv>
        <h1 className="flex-row-with-wrap">
          <span>Group Rule Map for Group {resId}</span>
        </h1>
        <StyledHeaderRowButtonDiv>
          {!groupRuleMapsData?.editMode && !working ? (
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleEditModeClick}
              style={{ display: "flex", alignItems: "center" }}
            >
              <span className="material-icons">edit</span>
              {"  "}Edit
            </button>
          ) : (
            !working && (
              <>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSaveClick}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "86px",
                  }}
                >
                  <span className="material-icons">check</span>
                  Save Rule Map
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCancelClick}
                  style={{ marginLeft: "12px" }}
                >
                  Cancel
                </button>
              </>
            )
          )}
        </StyledHeaderRowButtonDiv>
      </StyledHeaderRowDiv>
      {!(ruleMapsData.search.showAdvancedFilter && isMobileSize) && (
        <GridFreeFormSearchBar
          placeholderText="Search Rule Map"
          search={ruleMapsData.search}
          setSearch={setSearchChanges}
          numSetFilters={getNumberOfSetFilters()}
          onSubmitSearch={onSubmit}
          filterLinkEntityLabel={"Filter"}
        />
      )}

      {hasUnsavedChanges && !working && (
        <div
          className="flex-row-without-wrap"
          style={{ justifyContent: "right", marginBottom: "5px" }}
        >
          <span
            className="material-symbols-outlined"
            style={{ color: "var(--notify-warning)" }}
          >
            warning
          </span>
          &nbsp; You have unsaved changes&nbsp;
        </div>
      )}

      {working ? (
        <>
          <Spinner />
        </>
      ) : (
        <div style={{ display: "flex" }}>
          <GroupRuleMapSidebar
            onSaveNodeChangesToRuleMap={handleSaveNodeChangesToRuleMap}
            onDeleteNodeFromRuleMap={handleDeleteNodeFromRuleMap}
            onSaveRuleTableDataChangesToRuleMap={
              handleSaveRuleTableDataChangesToRuleMap
            }
            onSaveRuleTableDataScheduleChangesToRuleMap={
              handleSaveRuleTableDataScheduleChangesToRuleMap
            }
            onDeleteRuleTableDataFromRuleMap={
              handleDeleteRuleTableDataFromRuleMap
            }
            onCloseEditDialog={closeEditDialog}
            onSubmit={onSubmit}
            onReset={handleReset}
            onFilterItemClick={handleFilterItemClick}
          />
          <div
            id="content-div"
            style={{
              flex: "1 1 auto",
              height: `${
                windowSizeH > 800 ? windowSizeH - 240 : windowSizeH
              }px`,
            }}
          >
            {refreshing ? (
              <Spinner />
            ) : groupRuleMapsData?.isFilterSet ? (
              <RuleMapLayouter isGroupRuleMap={true} />
            ) : (
              <>
                {!(ruleMapsData.search.showAdvancedFilter && isMobileSize) && (
                  <StyledNoResultsDiv>
                    <p>
                      <i
                        className="material-icons"
                        style={{ fontSize: "32px" }}
                      >
                        info
                      </i>
                    </p>
                    <p>Select a field in the filter to view rule map</p>
                  </StyledNoResultsDiv>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </Authorize>
  );
}

export default GroupRuleMap;
