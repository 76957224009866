import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import _ from "lodash";
import {
  ReactFlow,
  Controls,
  useReactFlow,
  ReactFlowProvider,
  useNodesInitialized,
  MiniMap,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import RuleMapNode from "./RuleMapNode";
import RuleMapCodeArtifactNode from "./RuleMapCodeArtifactNode";
import { useUserPreferences } from "../../../contexts/UserPreferencesContext";
import RuleMapSimpleNode from "./RuleMapSimpleNode";
import RuleMapArtifactPreReqsNode from "./RuleMapArtifactPreReqsNode";
import RuleMapPossiblePathEdge from "./RuleMapPossiblePathEdge";
import RuleMapFlowToolbar from "./RuleMapFlowToolbar";
import { useRuleMaps } from "../../../contexts/RuleMapsContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import RuleMapNormalEdge from "./RuleMapNormalEdge";
import RuleMapModuleNode from "./RuleMapModuleNode";
import { getBadgeColorsFromType } from "./RuleMapDataCommon";
import { StyledNoResultsDiv } from "../../common/layout/CommonStyledControls";
import { useMobile } from "../../../hooks/useMobile";

// Define the nodeTypes and edgeTypes outside of the component to prevent re-renderings
const nodeTypes = {
  ruleMapNode: RuleMapNode,
  ruleMapCodeArtifactNode: RuleMapCodeArtifactNode,
  ruleMapArtifactPreReqsNode: RuleMapArtifactPreReqsNode,
  ruleMapSimpleNode: RuleMapSimpleNode,
  ruleMapModuleNode: RuleMapModuleNode,
};
const edgeTypes = {
  ruleMapPossiblePathEdge: RuleMapPossiblePathEdge,
  ruleMapNormalEdge: RuleMapNormalEdge,
};

const options = {
  includeHiddenNodes: true,
};

const MIN_ZOOM = 0.1;
const MAX_ZOOM = 1.5;
const defaultViewport = { x: 100, y: 100, zoom: 0.39 };

function RuleMapFlowDiagram(props) {
  const { isMobileSize, isTabletSize } = useMobile();
  const { userPrefs } = useUserPreferences();
  const { ruleMapsData, setRuleMapsData } = useRuleMaps();
  const { fitView, getNode, setCenter } = useReactFlow();
  const nodesInitialized = useNodesInitialized(options);
  const [detailLevel] = useState(1);

  useEffect(() => {
    if (nodesInitialized || ruleMapsData?.doRefreshLayout === true) {
      window.setTimeout(() => {
        props.onRelayoutNodes();
      });
    }
  }, [nodesInitialized, ruleMapsData?.doRefreshLayout]);

  useLayoutEffect(() => {
    if (ruleMapsData?.finalLayoutComplete === true) {
      window.setTimeout(() => {
        window.requestAnimationFrame(() => {
          callFitView();
        });
      });
    }
  }, [ruleMapsData?.finalLayoutComplete]);

  useEffect(() => {
    if (
      detailLevel !== ruleMapsData?.detailLevel &&
      ruleMapsData.finalLayoutComplete === true
    ) {
      reLayoutAllNodes();
      return;
    }
  }, [ruleMapsData?.detailLevel]);

  const zoomViewPort = useCallback(
    (duration, position) => {
      setCenter(position.x, position.y, { duration: duration, zoom: 1 });
    },
    [setCenter]
  );

  function callFitView() {
    if (
      ruleMapsData.selectedNodeId !== "-1" &&
      !ruleMapsData.isModuleViewMode
    ) {
      const selectedNode = getNode(ruleMapsData.selectedNodeId);
      fitView({
        nodes: [selectedNode],
        duration: 500,
      });
    } else {
      callFitViewAllNodes();
    }
  }

  function callFitViewAllNodes() {
    const array = ruleMapsData?.nodes || [];
    for (let i = 0; i < array.length; i++) {
      if (_.isEmpty(array[i].id)) {
        console.log("Empty id!", array[i]);
      }
    }

    fitView({
      nodes: array,
      duration: 500,
      minZoom: MIN_ZOOM,
      maxZoom: defaultViewport.zoom,
    });
  }

  function onNodeClick(event, node) {
    const isModuleViewClick = node.data.type?.description === "ModuleNode";
    const duration = isModuleViewClick ? 50 : 500;

    if (!isModuleViewClick) {
      zoomViewPort(duration, node.position);
    }

    window.setTimeout(() => {
      setRuleMapsData({
        type: ContextProviderActions.selectRuleMapNode,
        payload: {
          selectedNodeId: node.id,
          selectedNodeName: node.data.itemKey,
          selectedNodeType: node.data.type,
          moduleName: isModuleViewClick ? node.data.displayKey : "",
        },
      });
    }, duration + 100);
  }

  function onNodesChange(nodes) {
    setRuleMapsData({
      type: ContextProviderActions.onRuleMapNodesChange,
      payload: nodes,
    });
  }

  function onEdgesChange(edges) {
    setRuleMapsData({
      type: ContextProviderActions.onRuleMapEdgesChange,
      payload: edges,
    });
  }

  function reLayoutAllNodes() {
    window.setTimeout(() => {
      window.requestAnimationFrame(() => {
        props.onRelayoutNodes();
      });
    }, 100);
  }

  function getMinimapNodeColor(node) {
    const colors = getBadgeColorsFromType(
      node.data.type,
      node.data.isActualNodeLink,
      node.data.isProcessed
    );
    return colors.bgColor;
  }

  // Do not render the react flow diagram if advanced filter side bar is showing in mobile
  if (isMobileSize && ruleMapsData?.search.showAdvancedFilter === true)
    return <></>;

  return (
    <>
      {(ruleMapsData.nodes || []).length === 0 ? (
        <StyledNoResultsDiv>
          <p>
            <i className="material-icons">search_off</i>
          </p>
          <p>No results found</p>
        </StyledNoResultsDiv>
      ) : (
        <ReactFlow
          colorMode={userPrefs.darkMode ? "dark" : "light"}
          nodes={ruleMapsData?.nodes || []}
          edges={ruleMapsData?.edges || []}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          elementsSelectable={true}
          onNodeClick={onNodeClick}
          attributionPosition="bottom-right"
          nodesDraggable={false}
          defaultViewport={defaultViewport}
          minZoom={MIN_ZOOM}
          maxZoom={MAX_ZOOM}
        >
          {!isMobileSize && !isTabletSize && (
            <>
              <MiniMap pannable={true} nodeColor={getMinimapNodeColor} />

              <Controls position={"top-right"} />
            </>
          )}
          <RuleMapFlowToolbar
            zoomViewPort={zoomViewPort}
            callFitViewAllNodes={callFitViewAllNodes}
            showFieldLists={!props.isGroupRuleMap}
          />
        </ReactFlow>
      )}
    </>
  );
}

function RuleMapFlowDiagramProvider(props) {
  return (
    <ReactFlowProvider>
      <RuleMapFlowDiagram {...props} />
    </ReactFlowProvider>
  );
}

export default RuleMapFlowDiagramProvider;
