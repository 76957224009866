import React, { useState } from "react";
import _ from "lodash";
import {
  formatDateTimeUtcZoneForDisplay,
  generateUUID,
} from "../../../services/General";
import RuleDataStatusPill from "../../common/ui/RuleDataStatusPill";
import {
  StyledKeyHeader,
  StyledNoResultsDiv,
} from "../../common/layout/CommonStyledControls";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import { notifyWarn } from "../../../services/NotificationService";
import {
  createViewModel,
  emptyRuleDataRow,
  fromViewModel,
} from "../../../viewmodels/ruleTableDataVm";
import InstantSearchInput from "../../common/input/InstantSearchInput";
import CheckboxInput from "../../common/input/CheckboxInput";
import GroupRuleMapRuleTableDialog from "./GroupRuleMapRuleTableDialog";

function GroupRuleMapRuleTableEdit({
  ruleMap,
  ruleTableId,
  columns,
  records,
  colCount,
  search,
  setSearch,
  onSaveRuleTableDataChangesToRuleMap,
  onSaveRuleTableDataScheduleChangesToRuleMap,
  onDeleteRuleTableDataFromRuleMap,
}) {
  const [showModal, setShowModal] = useState(false);
  const [editDataRow, setEditDataRow] = useState(null);
  const [errors, setErrors] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deleteExtras, setDeleteExtras] = useState("");

  const today = new Date();

  const filteredDataRows = search.active
    ? records.filter(
        (r) =>
          (r.effectiveDate === null || new Date(r.effectiveDate) <= today) &&
          (r.terminationDate === null || new Date(r.terminationDate) > today)
      )
    : records;

  function handleEditDataRow(e, id) {
    if (e) e.preventDefault();
    handleEditRuleDataRow(id);
  }

  function getActions(dataRow) {
    const id = dataRow.rowId;

    const actions = [];

    actions.push({
      name: "Edit row data",
      onClick: () => handleEditDataRow(undefined, id),
    });

    // Only allow schedule change and delete if the rule table row comes from this rule (not another rule besides this one).
    //   We do not allow any changes to any inherited rule on this screen.
    if (dataRow.ruleId === ruleMap.ruleId) {
      actions.push({
        name: "Schedule change",
        onClick: () => handleScheduleDataRowChange(dataRow),
      });

      actions.push({
        name: "Remove",
        onClick: () => handleDelete(id, ruleTableId),
      });
    }

    return actions;
  }

  function getColumnDefs() {
    const cols = [];

    // Always-visible edit column
    cols.push({
      name: "edit",
      label: "",
      disableSort: true,
      isSticky: true,
      style: { width: "65px" },
      getValue: (row) => (
        <button
          type="button"
          className="btn btn-link link-underline"
          onClick={(e) => handleEditDataRow(e, row.rowId)}
          style={{ marginTop: "-4px" }}
        >
          edit
        </button>
      ),
    });

    // Status column
    cols.push({
      name: "status",
      label: "Status",
      disableSort: true,
      style: { width: "120px" },
      getValue: (row) => (
        <RuleDataStatusPill
          effectiveDate={row.effectiveDate}
          terminationDate={row.terminationDate}
          showTooltip={false}
        />
      ),
    });

    // Rule table columns
    let col;
    for (let i = 0; i < columns.length; i++) {
      col = columns[i];

      cols.push({
        name: col.name,
        label: col.isKey ? (
          <StyledKeyHeader>
            <span className="material-icons">key</span>
            <div>{col.name}</div>
          </StyledKeyHeader>
        ) : (
          <>{col.name}</>
        ),
        disableSort: true,
        freeTextSearch: true,
        getRawValue: (row) => {
          const val = (row.values || []).length > 0 ? row.values[i] : "";
          let value = val || "";

          if (columns[i]?.valueType === "Date") {
            value = formatDateTimeUtcZoneForDisplay(value);
          }

          return value;
        },
        getValue: (row, index, searchResultHtml) => searchResultHtml,
      });
    }

    // Source Rule column
    cols.push({
      name: "sourceRule",
      label: "Source Rule",
      disableSort: true,
      style: { width: "120px" },
      getValue: (row) => {
        // If this rule table row was created on this rule, don't show a source rule.
        if (row.ruleId === ruleMap.ruleId) return <></>;

        const ruleMetadata = (ruleMap.ruleMetadata || []).find(
          (r) => r.id === row.ruleId
        );
        if (!ruleMetadata) {
          console.log(
            `Rule with id ${row.ruleId} not found in rulemap rule metadata!`
          );
          return <>Not found!</>;
        }

        return ruleMetadata.name;
      },
    });

    // Always-visible action column
    cols.push({
      name: "action",
      label: "Action",
      isSticky: true,
      isActionBtn: true,
      disableSort: true,
      noForceWrap: true,
      style: { width: "60px", maxWidth: "25%", textAlign: "center" },
      getActions: (row) => getActions(row),
    });

    return cols;
  }

  function formIsValid() {
    const _errors = {};

    // Most validation is handled on the server, but here we will check to be sure all key columns and the effective date are filled.
    let val;
    columns
      .filter((c) => c.isKey === true)
      .forEach((c, idx) => {
        val = editDataRow.values[idx];
        if (!val || _.trim(val) === "") _errors[c.id] = `${c.name} is required`;
      });

    if (editDataRow.effectiveDate === null) {
      _errors.effectiveDate = "Effective Date is required";
    }

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function handleCloseEditDialog() {
    setEditDataRow(null);
    setErrors({});
    setShowModal(false);
  }

  async function deleteRuleDataRow(id, ruleTableId) {
    onDeleteRuleTableDataFromRuleMap(id);
  }

  async function handleDelete(id, ruleTableId) {
    setDeleteId(id);
    setDeleteExtras({ ruleTableId: ruleTableId });
    setShowDeleteModal(true);
  }

  async function performDelete() {
    setShowDeleteModal(false);
    await deleteRuleDataRow(
      deleteId,
      deleteExtras.ruleTableId,
      deleteExtras.isInherited
    );
  }

  async function handleSubmit(event) {
    if (event) event.preventDefault();
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }

    setShowModal(false);
    const newVm = { ...editDataRow, ruleTableId: ruleTableId };

    updateRuleDataRow(newVm.id, newVm);
  }

  async function updateRuleDataRow(id, vm) {
    const isInheritedRule = vm.ruleId !== ruleMap.ruleId;
    const inheritedRowId = isInheritedRule ? vm.rowId : "";

    var model = fromViewModel(vm);
    model.ruleTableId = ruleTableId;
    model.ruleId = ruleMap.ruleId;

    if (model.isScheduleChange === true) {
      onSaveRuleTableDataScheduleChangesToRuleMap(id, model);
    } else {
      // If this is an inherited rule, we will perform an insert of the edited rule table row instead and remove the inherited one (local only).
      if (isInheritedRule) {
        onSaveRuleTableDataChangesToRuleMap("", model, inheritedRowId);
      } else {
        onSaveRuleTableDataChangesToRuleMap(id, model);
      }
    }
  }

  function handleAddRuleDataRow() {
    setEditDataRow({ ...emptyRuleDataRow });
    setShowModal(true);
  }

  function handleEditRuleDataRow(id) {
    const model = records.find((r) => r.rowId === id);

    // For compatibility with rule table data view model - these are already strings
    model.effectiveDateString = model.effectiveDate;
    model.terminationDateString = model.terminationDate;
    model.id = id;

    const vm = createViewModel(model);
    setEditDataRow({ ...emptyRuleDataRow, ...vm });
    setShowModal(true);
  }

  function onActiveChange({ target }) {
    let updatedSearch = { ...search, active: target.checked };
    setSearch(updatedSearch);
  }

  function handleInstantSearchChange(value) {
    // If instant search is being performed, show ALL records.
    const pageSize =
      _.trim(value) !== ""
        ? 100000
        : search.pageSize > 250
        ? 25
        : search.pageSize;
    const pageNumber = _.trim(value) !== "" ? 1 : search.pageNumber;
    setSearch({
      ...search,
      highlightText: value,
      pageSize: pageSize,
      pageNumber: pageNumber,
    });
  }

  function handleScheduleDataRowChange(record) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const newRecord = {
      ...record,
      rowId: generateUUID(),
      previousValueRowId: record.rowId,
      isScheduleChange: true,
      effectiveDate: today.toISOString(),
    };
    setEditDataRow({ ...emptyRuleDataRow, ...newRecord });
    setShowModal(true);
  }

  return (
    <>
      <ConfirmDialog
        title="Remove Data Row"
        question={`Are you sure you wish to deactivate this data row?`}
        showModal={showDeleteModal}
        onNo={() => setShowDeleteModal(false)}
        onYes={performDelete}
      />
      <div
        className="flex-row-with-wrap"
        style={{ alignItems: "flex-start", marginBottom: "10px" }}
      >
        <div style={{ marginBottom: "10px" }}>
          <InstantSearchInput
            id="screenSearchInput"
            onChange={handleInstantSearchChange}
            value={search.highlightText}
          />
          <CheckboxInput
            id="active"
            label="Active Only"
            onChange={onActiveChange}
            placeholder="Active Only"
            name="active"
            showLabelInline={true}
            checked={search.active}
            error={errors.active}
          />
        </div>
      </div>

      {editDataRow !== null && (
        <GroupRuleMapRuleTableDialog
          editDataRow={editDataRow}
          setEditDataRow={setEditDataRow}
          columnSpecs={columns || []}
          errors={errors}
          showDialog={showModal}
          onCloseDialog={handleCloseEditDialog}
          onSaveDialog={handleSubmit}
        />
      )}

      <div style={{ marginTop: "20px" }}>
        <hr />
        <button
          type="button"
          className="btn btn-secondary btn-with-icon"
          onClick={handleAddRuleDataRow}
          style={{ marginLeft: "auto" }}
        >
          <span className="material-icons">add</span>
          {"  "}Add Data Row
        </button>

        {filteredDataRows.length === 0 ? (
          <div style={{ marginTop: "10px" }}>
            <StyledNoResultsDiv>
              <p>
                <i className="material-icons">search_off</i>
              </p>
              <p>No results found</p>
            </StyledNoResultsDiv>
          </div>
        ) : (
          <ResponsiveGrid
            gridId="GroupRuleMapRuleTableData"
            totalRecords={filteredDataRows.length}
            search={search}
            setSearch={setSearch}
            allowFreeTextSearch={true}
            dataRows={filteredDataRows}
            enableClientColumnPager={true}
            enableClientRowPager={true}
            totalColumnCount={colCount + 2}
            disablePaging={_.trim(search.highlightText) !== ""}
            hideDetailsButton={true}
            columnDefs={getColumnDefs()}
          />
        )}
      </div>
    </>
  );
}

export default GroupRuleMapRuleTableEdit;
