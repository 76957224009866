import React from "react";
import DatePickerInput from "../../common/input/DatePickerInput";
import CheckboxInput from "../../common/input/CheckboxInput";

function GroupRuleMapEditFieldDialogEffectiveFields({
  changes,
  errors,
  onEffectiveDateChange,
  onTerminationDateChange,
  onStagingChange,
}) {
  return (
    <>
      <DatePickerInput
        id="effectiveDate"
        name="effectiveDate"
        label="Effective Date"
        value={changes.effectiveDate}
        placeholder="Effective Date"
        onChange={onEffectiveDateChange}
        showTimeInput={false}
        error={errors.effectiveDate}
      />
      <DatePickerInput
        id="terminationDate"
        label="Termination Date"
        name="terminationDate"
        value={changes.terminationDate}
        placeholder="Termination Date"
        onChange={onTerminationDateChange}
        showTimeInput={false}
        error={errors.terminationDate}
      />
      <CheckboxInput
        id="staging"
        label="Staging"
        onChange={onStagingChange}
        placeholder="Staging"
        name="staging"
        checked={changes.staging}
        error={errors.staging}
      />
    </>
  );
}

export default GroupRuleMapEditFieldDialogEffectiveFields;
