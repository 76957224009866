import React, { useEffect, useState } from "react";
import _ from "lodash";
import { notifyWarn } from "../../../services/NotificationService";
import { useRuleMaps } from "../../../contexts/RuleMapsContext";
import GroupRuleMapEditFieldDialogEffectiveFields from "./GroupRuleMapEditFieldDialogEffectiveFields";
import GroupRuleMapEditFieldDialogKeyFields from "./GroupRuleMapEditFieldDialogKeyFields";
import GroupRuleMapEditFieldDialogConditionFieldsSection from "./GroupRuleMapEditFieldDialogConditionFieldsSection";
import GroupRuleMapEditFieldDialogFlagModuleFields from "./GroupRuleMapEditFieldDialogFlagModuleFields";
import GroupRuleMapEditFieldDialogValueFields from "./GroupRuleMapEditFieldDialogValueFields";
import GroupRuleMapEditFieldDialogRuleFieldsSection from "./GroupRuleMapEditFieldDialogRuleFieldsSection";
import ExpandCollapse from "../../common/layout/ExpandCollapse";
import GroupRuleMapEditFieldDialogHeader from "./GroupRuleMapEditFieldDialogHeader";
import { emptyRuleMapCondition } from "../../../viewmodels/groupRuleMapsVm";
import styled from "styled-components";
import {
  getNodeForGroupRuleMapById,
  NodeTypes,
} from "../rulemaps/RuleMapDataCommon";
import { useGroupRuleMaps } from "../../../contexts/GroupRuleMapsContext";
import { useMobile } from "../../../hooks/useMobile";
import { ALL_CLAIMS_RULE_ID } from "../../../services/General";

function GroupRuleMapAddEditField({
  editField,
  ruleId,
  onSaveItem,
  onCancelItem,
  onDeleteItem,
}) {
  const { isMobileSize } = useMobile();
  const { ruleMapsData } = useRuleMaps();
  const { groupRuleMapsData } = useGroupRuleMaps();
  const [errors, setErrors] = useState({});
  const [editItem, setEditItem] = useState(null);
  const [changes, setChanges] = useState(null);
  const [attachType, setAttachType] = useState("Flag");
  const [ruleOptions, setRuleOptions] = useState([]);
  const [moduleOptions, setModuleOptions] = useState([]);

  const isAdd = editItem === null ? true : editItem.isNew;

  useEffect(() => {
    // On dialog open, set changes to the current edit field so we can detect when we have actual changes
    setEditItem(editField);
    setChanges(editField);
    setAttachType(_.isEmpty(editField.flagName) ? "Module" : "Flag");
  }, [groupRuleMapsData?.editFields]);

  useEffect(() => {
    if (ruleMapsData && ruleMapsData.ruleMap) {
      // Get rule options for dropdown - keep in hierarchy order
      const rules = ruleMapsData.ruleMap.ruleMetadata.map((r) => {
        return { value: r.id, label: r.name };
      });
      setRuleOptions(rules);

      // Get module options for dropdown - order by name
      const modules = ruleMapsData.ruleMap.modules
        .map((r) => {
          return { value: r.name, label: r.name };
        })
        .sort((a, b) => (a.label > b.label ? 1 : -1));
      setModuleOptions(modules);
    }
  }, [ruleMapsData?.ruleMap]);

  function formIsValid() {
    const _errors = {};

    // Validate key fields
    if (!_.has(changes, "identifier") || changes.identifier.trim() === "")
      _errors.identifier = "Identifier must be entered";
    if (!_.has(changes, "itemKey") || changes.itemKey.trim() === "")
      _errors.itemKey = "Item Key must be entered";

    // Has this key field changed? If so, is it unique across all other fields in the same rule map array?
    if (isAdd || changes.displayKey !== editItem.displayKey) {
      const existingNode = getNodeForGroupRuleMapById(
        ruleMapsData.ruleMap,
        `${changes.type.description}-${changes.displayKey}`
      );
      if (existingNode !== null) {
        _errors.identifier =
          "The combination of Identifier and Item Key must be unique across all fields";
      }
    }

    // Validate flag and module fields
    if (attachType === "Flag") {
      if (!_.has(changes, "flagName") || changes.flagName.trim() === "")
        _errors.flagName = "Flag must be entered";
    }
    if (attachType === "Module") {
      if ((changes.modules || []).length < 1)
        _errors.module = "A Module must be selected";
    }

    // Validate value fields
    if (changes.type === NodeTypes.InternalVariable) {
      if (!_.has(changes, "valueType") || changes.valueType.trim() === "")
        _errors.valueType = "A Value Type must be selected";
    }
    if (!_.has(changes, "itemValue") || changes.itemValue.trim() === "")
      _errors.itemValue = "Item Value must be entered";
    if (changes.type === NodeTypes.Rejection) {
      if (!_.has(changes, "level") || changes.level.trim() === "")
        _errors.level = "A Level must be selected";
    }

    // Validate condition fields
    const conditions = (changes.actionPrerequisites || []).filter(
      (r) => r.isAttachedByArtifact === false && r.condition === "IsCompared"
    );

    // Included Rejections must have a condition
    if (changes.type === NodeTypes.Rejection && conditions.length < 1) {
      _errors.includedRejectionCondition =
        "Included Rejections must have a condition";
    }

    if (conditions.length > 0) {
      const condition = conditions[0];
      if (
        !_.has(condition, "sourceType") ||
        condition.sourceType?.description?.trim() === ""
      )
        _errors.conditionSourceType = "A Key Type must be selected";
      if (!_.has(condition, "sourceKey") || condition.sourceKey?.trim() === "")
        _errors.conditionSourceKey = "Item Key must be entered";
      if (
        !_.has(condition, "comparison") ||
        condition.comparison?.trim() === ""
      )
        _errors.conditionComparison = "An Operator must be selected";
      if ((condition.comparisonValues || []).length < 1) {
        _errors.conditionComparisonValues = "Value must be entered";
      }
    }

    // A Rule must be selected to save to - if all rule is selected, it isn't really since that rule never appears in the dropdown.
    if (
      _.isEmpty(changes.ruleId) ||
      changes.ruleId === "-1" ||
      changes.ruleId === ALL_CLAIMS_RULE_ID
    )
      _errors.ruleId = "A Rule must be selected";

    // Effective/Staging fields
    let isDateError = false;
    if (!_.isEmpty(changes.effectiveDate) && !_.isDate(changes.effectiveDate)) {
      isDateError = true;
      _errors.effectiveDate = "Effective Date must be a valid date";
    }
    if (
      !_.isEmpty(changes.terminationDate) &&
      !_.isDate(changes.terminationDate)
    ) {
      isDateError = true;
      _errors.terminationDate = "Termination Date must be a valid date";
    }

    if (!isDateError) {
      if (!_.isDate(changes.effectiveDate)) changes.effectiveDate = null;
      else {
        changes.effectiveDate = new Date(changes.effectiveDate.toISOString());
      }
      if (!_.isDate(changes.terminationDate)) changes.terminationDate = null;
      else {
        changes.terminationDate = new Date(
          changes.terminationDate.toISOString()
        );
      }
      if (
        changes.effectiveDate !== null &&
        changes.terminationDate !== null &&
        changes.effectiveDate.toISOString() >
          changes.terminationDate.toISOString()
      ) {
        _errors.terminationDate =
          "Termination Date must be later than or equal to Effective Date";
      }
    }

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function handleSubmit(event, updated) {
    if (event) event.preventDefault();
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }
    const newVm = updated ? updated : { ...changes };
    newVm.isAdd = isAdd;
    onSaveItem(newVm);
  }

  function handleChange({ target }) {
    let changed = { ...changes, [target.name]: target.value };
    setChanges(changed);
  }

  function handleKeyFieldChange({ target }) {
    let changed = { ...changes, [target.name]: target.value };

    // Also update the display key for this node when the keys change
    const identifier =
      target.name === "identifier" ? target.value : changed.identifier;
    const itemKey = target.name === "itemKey" ? target.value : changed.itemKey;

    changed.displayKey = `${identifier}/${itemKey}`;
    setChanges(changed);
  }

  function handleEffectiveDateChange(date) {
    setChanges({ ...changes, effectiveDate: date });
  }

  function handleTerminationDateChange(date) {
    setChanges({ ...changes, terminationDate: date });
  }

  function handleRuleChange(option) {
    let value = option.value;

    // If no Save to Rule is selected, revert rule back to the original rule the field was on.
    if (value === "-1") {
      value = editItem.ruleId;
    }
    setChanges({ ...changes, ruleId: value });
  }

  function handleStagingChange({ target }) {
    let changed = { ...changes };
    changed.staging = target.checked;
    setChanges(changed);
  }

  function handleRejectionLevelChange(option) {
    setChanges({
      ...changes,
      level: option.value,
    });
  }

  function handleValueTypeChange(option) {
    setChanges({
      ...changes,
      valueType: option.value,
    });
  }

  function getConditionFromIdentifier(changed, conditionId) {
    const condition = (changed.actionPrerequisites || []).find(
      (r) => r.identifier === conditionId
    );
    if (!condition) {
      console.log(
        `Condition with identifier ${conditionId} not found in handleConditionKeyTypeChange!`
      );
    }
    return condition;
  }

  function handleConditionChange(conditionId, target) {
    let changed = { ...changes };
    const condition = getConditionFromIdentifier(changed, conditionId);
    condition[target.name] = target.value;
    setChanges(changed);
  }

  function handleConditionKeyTypeChange(conditionId, option) {
    let changed = { ...changes };
    const condition = getConditionFromIdentifier(changed, conditionId);
    condition.sourceType = option.value;
    setChanges(changed);
  }

  function handleConditionOperatorChange(conditionId, option) {
    let changed = { ...changes };
    const condition = getConditionFromIdentifier(changed, conditionId);
    condition.comparison = option.value;
    setChanges(changed);
  }

  function handleConditionComparisonValueChange(conditionId, value) {
    let changed = { ...changes };
    const condition = getConditionFromIdentifier(changed, conditionId);

    // Assume exactly one comparison value in the array at most
    if ((condition.comparisonValues || []).length < 1) {
      condition.comparisonValues = [];
      condition.comparisonValues.push(value);
    } else {
      condition.comparisonValues[0] = value;
    }

    setChanges(changed);
  }

  function handleModuleChange(option) {
    const value = option === null ? "" : option.value;
    let changed = { ...changes };
    if ((changed.modules || []).length < 1) {
      changed.modules = [];
      changed.modules.push({ name: value, moduleType: "Primary" });
    } else {
      changes.modules[0].name = value;
    }
    setChanges(changed);
  }

  function handleAttachTypeChange(value) {
    setAttachType(value);
    if (value === "Flag") {
      setChanges({
        ...changes,
        modules: [],
      });
    } else {
      setChanges({
        ...changes,
        flagName: "",
      });
    }
  }

  function handleAddCondition() {
    let changed = { ...changes };

    if ((changed.actionPrerequisites || []).length === 0) {
      changed.actionPrerequisites = [];
    }
    changed.actionPrerequisites.push({ ...emptyRuleMapCondition });

    setChanges(changed);
  }

  function handleRemoveCondition() {
    let changed = { ...changes };
    const conditionIndex = (changed.actionPrerequisites || []).findIndex(
      (r) => r.isAttachedByArtifact === false && r.condition === "IsCompared"
    );
    if (conditionIndex >= 0) {
      changed.actionPrerequisites.splice(conditionIndex, 1);
    }

    setChanges(changed);
  }

  if (!editItem || editItem === null) return <></>;

  const width = isMobileSize ? 360 : 400;
  const isFieldOnInheritedRule = ruleId !== editField.ruleId;

  return (
    <div
      style={{
        width: width,
        marginLeft: isMobileSize ? "-5px" : "0",
        padding: isMobileSize ? "20px 0 0 0" : "0",
      }}
    >
      <StyledFilterBox
        data-testid="advanced-filter-box"
        width={width}
        style={{
          marginBottom: "20px",
          border: "1px solid var(--button-secondary-border)",
        }}
      >
        <StyledFilterBoxHeader>
          <h4>{isAdd ? "Add" : "Edit"} Field</h4>
          {!isAdd && !isFieldOnInheritedRule && (
            <button
              className="btn btn-link btn-with-icon"
              title="Remove this field from the rule map"
              onClick={() => onDeleteItem({ ...changes })}
            >
              <span className="material-icons">close</span>
              <span
                style={{ display: "inline-block", margin: "-4px 0 0 -4px" }}
              >
                remove
              </span>
            </button>
          )}
        </StyledFilterBoxHeader>
        <StyledFilterBoxContents>
          <form>
            <GroupRuleMapEditFieldDialogHeader changes={changes} />
            <ExpandCollapse
              id="groupRuleDialogDetails"
              title="Details"
              defaultState={true}
            >
              <GroupRuleMapEditFieldDialogKeyFields
                changes={changes}
                errors={errors}
                onChange={handleKeyFieldChange}
              />
              <br />
              <GroupRuleMapEditFieldDialogFlagModuleFields
                attachType={attachType}
                changes={changes}
                errors={errors}
                moduleOptions={moduleOptions}
                onChange={handleChange}
                onAttachTypeChange={handleAttachTypeChange}
                onModuleChange={handleModuleChange}
              />
              <br />
              <GroupRuleMapEditFieldDialogValueFields
                changes={changes}
                errors={errors}
                onChange={handleChange}
                onValueTypeChange={handleValueTypeChange}
                onRejectionLevelChange={handleRejectionLevelChange}
              />
            </ExpandCollapse>

            <GroupRuleMapEditFieldDialogConditionFieldsSection
              changes={changes}
              isAdd={isAdd}
              errors={errors}
              onConditionChange={handleConditionChange}
              onConditionKeyTypeChange={handleConditionKeyTypeChange}
              onConditionOperatorChange={handleConditionOperatorChange}
              onConditionComparisonValueChange={
                handleConditionComparisonValueChange
              }
              onAddCondition={handleAddCondition}
              onRemoveCondition={handleRemoveCondition}
            />

            <GroupRuleMapEditFieldDialogRuleFieldsSection
              ruleOptions={ruleOptions}
              changes={changes}
              isAdd={isAdd}
              errors={errors}
              onRuleChange={handleRuleChange}
            />

            <ExpandCollapse
              id="groupRuleDialogEffective"
              title="Effective/Staging"
              defaultState={false}
            >
              <GroupRuleMapEditFieldDialogEffectiveFields
                changes={changes}
                errors={errors}
                onEffectiveDateChange={handleEffectiveDateChange}
                onTerminationDateChange={handleTerminationDateChange}
                onStagingChange={handleStagingChange}
              />
            </ExpandCollapse>
          </form>

          <div className="grid-search-form-action-row">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
              style={{
                minWidth: "86px",
              }}
            >
              {isAdd ? "Add" : "Update"} Field
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onCancelItem}
              style={{ marginLeft: "12px" }}
            >
              Cancel
            </button>
          </div>
        </StyledFilterBoxContents>
      </StyledFilterBox>
    </div>
  );
}

const StyledFilterBox = styled.div`
  background: var(--elevated-bg);
  border: 1px solid var(--elevated-border2);
  border-radius: 3px;

  h4 {
    color: var(--text-dark);
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    margin: 0;
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
`;

const StyledFilterBoxHeader = styled.div`
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledFilterBoxContents = styled.div`
  border-top: 1px solid var(--elevated-border2);
  padding: 1px 20px 20px 20px;
`;

export default GroupRuleMapAddEditField;
