import React from "react";
import styled from "styled-components";

function Badge({ badgeStyle = {}, children }) {
  return <StyledBadge style={{ ...badgeStyle }}>{children}</StyledBadge>;
}

const StyledBadge = styled.span`
  height: 25px;
  line-height: 25px;
  min-width: 25px;
  border-radius: 20px;
  font-size: 13px;
  font-weight: bold;
  padding: 0px 4px 3px 4px;
  margin: 0;
  display: inline-block;
  text-align: center;
  color: var(--badge-text);
  background-color: var(--badge-bg);
  border: 1px solid var(--badge-border);
  font-family: "Times New Roman", Times, serif;
`;

export default Badge;
