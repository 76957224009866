import React from "react";
import { StyledCharblockDiv } from "../rulemaps/RuleMapCommonStyles";
import {
  getBadgeColorsFromType,
  getNodeTypeAbbreviation,
} from "../rulemaps/RuleMapDataCommon";

function GroupRuleMapEditFieldDialogHeader({ changes }) {
  function getDisplayKey() {
    let identifer = changes.identifier ? (
      changes.identifier
    ) : (
      <i>No identifier</i>
    );
    let itemKey = changes.itemKey ? changes.itemKey : <i>No item key</i>;

    return (
      <>
        {identifer}/{itemKey}
      </>
    );
  }

  return (
    <div style={{ marginTop: "20px" }}>
      <div className="flex-row-with-wrap-and-justify">
        <h4>{getDisplayKey()}</h4>
        <div className="flex-row-with-wrap">
          <StyledCharblockDiv
            className="charblock"
            colors={getBadgeColorsFromType(changes.type)}
            style={{ marginRight: "8px" }}
          >
            {getNodeTypeAbbreviation(changes.type)}
          </StyledCharblockDiv>
          {changes.type.description}
        </div>
      </div>
    </div>
  );
}

export default GroupRuleMapEditFieldDialogHeader;
