import React, { useEffect, useState } from "react";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useMobile } from "../../../hooks/useMobile";
import { useRuleMaps } from "../../../contexts/RuleMapsContext";
import GridAdvancedFilter from "../../common/grid/GridAdvancedFilter";
import GroupRuleMapFilterForm from "./GroupRuleMapFilterForm";
import GroupRuleMapNewFieldToolbar from "./GroupRuleMapNewFieldToolbar";
import { useGroupRuleMaps } from "../../../contexts/GroupRuleMapsContext";
import GroupRuleMapAddEditField from "./GroupRuleMapAddEditField";

function GroupRuleMapSidebar({
  onSaveNodeChangesToRuleMap,
  onDeleteNodeFromRuleMap,
  onSaveRuleTableDataChangesToRuleMap,
  onSaveRuleTableDataScheduleChangesToRuleMap,
  onDeleteRuleTableDataFromRuleMap,
  onCloseEditDialog,
  onSubmit,
  onReset,
  onFilterItemClick,
}) {
  const { isMobileSize, isTabletSize } = useMobile();
  const { ruleMapsData, setRuleMapsData } = useRuleMaps();
  const { groupRuleMapsData } = useGroupRuleMaps();
  const [defaultRuleSelections, setDefaultRuleSelections] = useState([]);

  useEffect(() => {
    if (ruleMapsData?.ruleMap && ruleMapsData?.ruleMap.ruleMetadata) {
      // Select all rules by default
      const selections = ruleMapsData.ruleMap.ruleMetadata.map((r, idx) => {
        return { id: r.id, checked: true };
      });
      setDefaultRuleSelections(selections);
    }
  }, []);

  function setSearchChanges(search) {
    setRuleMapsData({
      type: ContextProviderActions.saveGroupRuleMapFilter,
      payload: search,
    });
  }

  function handleMobileSubmit(event, newSearch) {
    var updatedSearch = {
      ...ruleMapsData.search,
      ...newSearch,
      showAdvancedFilter: isMobileSize
        ? false
        : ruleMapsData.search.showAdvancedFilter,
    };

    onSubmit(event, updatedSearch);
  }

  const editFieldsCount = (groupRuleMapsData?.editFields || []).length;

  // Wait to have the rule selections loaded or the filter will not work properly
  if (defaultRuleSelections.length < 1) return <></>;

  return (
    <>
      {editFieldsCount <= 1 || isMobileSize || isTabletSize ? (
        // Single edit field - show new field area if not editing
        <div
          style={{
            width:
              ruleMapsData.search.showAdvancedFilter && isMobileSize
                ? "100%"
                : "auto",
          }}
        >
          {groupRuleMapsData?.editMode &&
            editFieldsCount === 0 &&
            (!isMobileSize ||
              (ruleMapsData.search.showAdvancedFilter && isMobileSize)) && (
              <GroupRuleMapNewFieldToolbar
                ruleMap={ruleMapsData?.ruleMap}
                onSaveRuleTableDataChangesToRuleMap={
                  onSaveRuleTableDataChangesToRuleMap
                }
                onSaveRuleTableDataScheduleChangesToRuleMap={
                  onSaveRuleTableDataScheduleChangesToRuleMap
                }
                onDeleteRuleTableDataFromRuleMap={
                  onDeleteRuleTableDataFromRuleMap
                }
              />
            )}
          {(groupRuleMapsData?.editFields || []).map((editField, idx) => (
            <GroupRuleMapAddEditField
              key={`editDialog-${idx}`}
              ruleId={ruleMapsData.ruleMap?.ruleId || ""}
              editField={editField}
              onSaveItem={onSaveNodeChangesToRuleMap}
              onDeleteItem={onDeleteNodeFromRuleMap}
              onCancelItem={() => {
                onCloseEditDialog(editField.id);
                return null;
              }}
            />
          ))}
          <GridAdvancedFilter
            search={ruleMapsData.search}
            setSearch={setSearchChanges}
            width={400}
            marginTop={"20px"}
            helpLink="/GroupRuleMap/GroupRule-Map-Screen&anchor=filters"
          >
            <GroupRuleMapFilterForm
              search={ruleMapsData.search}
              onSearch={isMobileSize ? handleMobileSubmit : onSubmit}
              onReset={onReset}
              ruleMap={ruleMapsData?.ruleMap}
              defaultRuleSelections={defaultRuleSelections || []}
              selectedField={ruleMapsData?.selectedField}
              onFilterItemClick={onFilterItemClick}
            />
          </GridAdvancedFilter>
        </div>
      ) : (
        // Multiple fields edit - never show add field area
        <div
          style={{
            width:
              ruleMapsData.search.showAdvancedFilter && isMobileSize
                ? "100%"
                : "auto",
          }}
        >
          <div style={{ display: "flex" }}>
            {(groupRuleMapsData?.editFields || []).map((editField, idx) => (
              <GroupRuleMapAddEditField
                key={`editDialog-${idx}`}
                editField={editField}
                onSaveItem={onSaveNodeChangesToRuleMap}
                onDeleteItem={onDeleteNodeFromRuleMap}
                onCancelItem={() => {
                  onCloseEditDialog(editField.id);
                  return null;
                }}
              />
            ))}
          </div>
          <GridAdvancedFilter
            search={ruleMapsData.search}
            setSearch={setSearchChanges}
            width={400}
            helpLink="/GroupRuleMap/GroupRule-Map-Screen&anchor=filters"
          >
            <GroupRuleMapFilterForm
              search={ruleMapsData.search}
              onSearch={onSubmit}
              onReset={onReset}
              ruleMap={ruleMapsData?.ruleMap}
              defaultRuleSelections={defaultRuleSelections || []}
              selectedField={ruleMapsData?.selectedField}
              onFilterItemClick={onFilterItemClick}
            />
          </GridAdvancedFilter>
        </div>
      )}
    </>
  );
}

export default GroupRuleMapSidebar;
