import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { convertUTCDateToLocal } from "../../../services/General";

function RuleDataStatusPill({
  effectiveDate,
  terminationDate,
  showTooltip = true,
}) {
  function formatDateTimeUtcZoneForDisplay(date) {
    if (date === null) return "";
    if (!_.endsWith(date, "Z")) date = date + "Z";
    let dt = getLocalDateFormatForDisplay(date);

    if (dt.endsWith(" 12:00:00a")) {
      dt = dt.substring(0, dt.indexOf(" 12:00:00a"));
    }

    return dt;
  }

  function getLocalDateFormatForDisplay(date) {
    let dt = new Date(date).toLocaleString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit",
    });

    // Remove comma separating date and time
    dt = dt.replaceAll(",", "");

    // Replace PM and AM with p and a
    dt = dt.replaceAll(" AM", "a");
    dt = dt.replaceAll(" PM", "p");

    return dt;
  }

  function getStatusMessage() {
    let msg = "";
    if (!showTooltip) return "";

    if (effectiveDate !== null && terminationDate !== null) {
      msg = `Effective ${formatDateTimeUtcZoneForDisplay(
        effectiveDate
      )} to ${formatDateTimeUtcZoneForDisplay(terminationDate)}`;
    } else if (effectiveDate !== null) {
      msg = `Effective ${formatDateTimeUtcZoneForDisplay(effectiveDate)}`;
    } else if (terminationDate !== null) {
      msg = `Effective until ${formatDateTimeUtcZoneForDisplay(
        terminationDate
      )}`;
    }
    return msg;
  }

  let pill = (
    <StyledStatusPill data-tip={getStatusMessage()}>Active</StyledStatusPill>
  );
  const start =
    effectiveDate === null
      ? new Date("1/1/1900")
      : convertUTCDateToLocal(effectiveDate);
  const end =
    terminationDate === null
      ? new Date("12/31/3000")
      : convertUTCDateToLocal(terminationDate);
  const today = new Date();

  if (start > today)
    pill = (
      <StyledStatusPill
        style={{ backgroundColor: "var(--pill-blue)" }}
        data-tip={getStatusMessage()}
      >
        Future
      </StyledStatusPill>
    );
  else if (end < today)
    pill = (
      <StyledStatusPill
        style={{ backgroundColor: "var(--pill-red)" }}
        data-tip={getStatusMessage()}
      >
        Expired
      </StyledStatusPill>
    );

  return pill;
}

const StyledStatusPill = styled.span`
  height: 24px;
  border-radius: 12px;
  padding: 3px 12px;
  font-size: 15px;
  line-height: 20px;
  color: var(--pill-text);
  background-color: var(--pill-green);
`;

export default RuleDataStatusPill;
