import React, { useEffect, useState } from "react";
import { StyledControlPanel, StyledDescription } from "./RuleMapCommonStyles";
import SelectInput from "../../common/input/SingleSelect";
import { useRuleMaps } from "../../../contexts/RuleMapsContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import {
  getBadgeColorsFromType,
  getNodeTypeAbbreviation,
  NodeTypes,
} from "./RuleMapDataCommon";
import SliderInput from "../../common/input/SliderInput";
import { useMobile } from "../../../hooks/useMobile";

const TOOLBAR_SELECT_CONTROL_STYLES = {
  maxWidth: "200px",
  width: "200px",
  height: "40px",
  maxHeight: "40px",
};

const TOOLBAR_SELECT_INPUT_STYLES = {
  fontSize: "14px",
  lineHeight: "16px",
};

function RuleMapFlowToolbar({
  zoomViewPort,
  callFitViewAllNodes,
  showFieldLists = true,
}) {
  const { isMobileSize, isTabletSize } = useMobile();
  const { ruleMapsData, setRuleMapsData } = useRuleMaps();
  const [selectedField, setSelectedField] = useState({
    label: "(All Fields)",
    value: "-1",
  });
  const [selectedNode, setSelectedNode] = useState({
    label: "(No Node Selected)",
    value: "-1",
  });

  useEffect(() => {
    if (ruleMapsData.selectedField === "-1") {
      setSelectedField({
        label: "(All Fields)",
        value: "-1",
      });
    }
    if (ruleMapsData.selectedNodeId === "-1") {
      setSelectedNode({
        label: "(No Node Selected)",
        value: "-1",
      });
    } else {
      const list = buildNodesSelectList();
      setSelectedNode({
        label:
          list.find((l) => l.value === ruleMapsData.selectedNodeId)?.label ||
          "(Node not found)",
        value: ruleMapsData.selectedNodeId,
      });
    }
  }, [ruleMapsData.selectedField, ruleMapsData.selectedNodeId]);

  function buildOptionsSelectList() {
    const options = [];

    options.push({
      label: "(All Fields)",
      value: "-1",
      type: NodeTypes.Unknown,
    });

    let array = ruleMapsData.responseFieldsList;
    for (let i = 0; i < array.length; i++) {
      options.push({
        ...array[i],
        type: array[i].type,
      });
    }
    array = ruleMapsData.rejectionsList;
    for (let i = 0; i < array.length; i++) {
      options.push({
        ...array[i],
        type: array[i].type,
      });
    }

    return options;
  }

  function getNodeListLabel(node) {
    const label = (
      <StyledDescription
        className="flex-row-without-wrap"
        colors={getBadgeColorsFromType(
          node.data.type,
          node.data.isActualNodeLink,
          node.data.isProcessed
        )}
      >
        <span className="charblock" style={{ fontSize: "13px" }}>
          {getNodeTypeAbbreviation(node.data.type, true, true)}
        </span>
        <span className="option-member-name normal-case">
          {node.data.itemKey}
        </span>
      </StyledDescription>
    );
    return label;
  }

  function buildNodesSelectList() {
    const options = [];

    options.push({
      label: "(No Node Selected)",
      value: "-1",
      type: "",
      name: "",
    });

    const array = (ruleMapsData.nodes || []).sort((a, b) =>
      a.data.itemKey > b.data.itemKey ? 1 : -1
    );

    for (let i = 0; i < array.length; i++) {
      options.push({
        value: array[i].data.id,
        name: array[i].data.itemKey,
        label: getNodeListLabel(array[i]),
        type: array[i].data.type,
      });
    }

    return options;
  }

  function handleFieldChanged(option) {
    setSelectedField(option);
    setRuleMapsData({
      type: ContextProviderActions.selectRuleMapField,
      payload: { selectedField: option },
    });
    doRefreshAfterOperation();
  }

  function handleSelectedNodeChanged(option, isModuleSelect = false) {
    setSelectedNode(option);
    const duration = isModuleSelect ? 50 : 500;
    let node = null;

    if (option.value === "-1") {
      callFitViewAllNodes();
    } else {
      node = ruleMapsData.nodes.find((n) => n.data.id === option.value);
      if (!isModuleSelect && option.type !== NodeTypes.ModuleNode) {
        zoomViewPort(duration, node?.position);
      } else {
        callFitViewAllNodes();
      }
    }

    window.setTimeout(() => {
      setRuleMapsData({
        type: ContextProviderActions.selectRuleMapNode,
        payload: {
          selectedNodeId: option.value,
          selectedNodeName: option.name,
          selectedNodeType: option.type,
          moduleName:
            isModuleSelect && node !== null ? node.data.displayKey : "",
        },
      });
    }, duration + 200);
  }

  function doRefreshAfterOperation() {
    window.setTimeout(() => {
      window.requestAnimationFrame(() => {
        setRuleMapsData({
          type: ContextProviderActions.doRuleMapRefreshLayout,
          payload: null,
        });
      });
    }, 100);
  }

  function onResetSelections() {
    if (ruleMapsData.isModuleViewMode) {
      onExitModuleView();
      return;
    }

    setRuleMapsData({
      type: ContextProviderActions.resetRuleMapSelections,
      payload: null,
    });
  }

  function handleLayoutDirectionChange(newDirection) {
    setRuleMapsData({
      type: ContextProviderActions.setRuleMapLayoutDirection,
      payload: newDirection,
    });
  }

  function handleShowPossiblePathsChange() {
    setRuleMapsData({
      type: ContextProviderActions.setRuleMapShowPossiblePaths,
      payload: !ruleMapsData.showPossiblePaths,
    });
  }

  function handleShowModulesChange() {
    setRuleMapsData({
      type: ContextProviderActions.setRuleMapShowModules,
      payload: !ruleMapsData.showModules,
    });
  }

  function onExitModuleView() {
    setRuleMapsData({
      type: ContextProviderActions.closeModuleViewMode,
      payload: null,
    });
  }

  function setDetailLevel(e) {
    const level = parseInt(e.target.value, 10);
    setRuleMapsData({
      type: ContextProviderActions.setRuleMapDetailLevel,
      payload: level,
    });
  }

  const showPossiblePaths = ruleMapsData?.showPossiblePaths || false;
  const showModules = ruleMapsData?.showModules;
  const layoutDirection = ruleMapsData?.layoutDirection || "TB";
  const isModuleViewMode = ruleMapsData?.isModuleViewMode || false;

  return (
    <>
      {(isModuleViewMode || showFieldLists) && (
        <StyledControlPanel position="top-left">
          {!isModuleViewMode && showFieldLists && (
            <>
              <SelectInput
                id="selectFields"
                name="selectFields"
                label=""
                labelStyle={{ display: "none" }}
                options={[...buildOptionsSelectList()]}
                value={selectedField}
                onChange={handleFieldChanged}
                placeholder="Select"
                controlStyle={{
                  ...TOOLBAR_SELECT_CONTROL_STYLES,
                  width: "300px",
                  maxWidth: "300px",
                  marginRight: "10px",
                }}
                selectStyle={{ ...TOOLBAR_SELECT_INPUT_STYLES }}
              />
            </>
          )}
          {isModuleViewMode && (
            <div className="flex-row-without-wrap">
              <button
                title="Exit module view"
                className="btn btn-primary btn-with-icon"
                onClick={onExitModuleView}
                style={{ marginRight: "12px", width: "130px" }}
              >
                <span className="material-symbols-outlined">close</span>
                Close Module
              </button>
              <h4 style={{ marginRight: "50px", marginTop: "6px" }}>
                {ruleMapsData.selectedModuleName}
              </h4>
            </div>
          )}

          {showFieldLists && (
            <SelectInput
              id="nodes"
              name="nodes"
              label=""
              labelStyle={{ display: "none" }}
              options={[...buildNodesSelectList()]}
              value={selectedNode}
              onChange={(option) => handleSelectedNodeChanged(option)}
              placeholder="Select"
              controlStyle={{
                ...TOOLBAR_SELECT_CONTROL_STYLES,
                width: "250px",
                maxWidth: "250px",
              }}
              selectStyle={{ ...TOOLBAR_SELECT_INPUT_STYLES }}
            />
          )}
        </StyledControlPanel>
      )}
      <StyledControlPanel
        position={
          (isMobileSize || isTabletSize) && !showFieldLists
            ? "top-left"
            : "top-right"
        }
      >
        <div style={{ margin: "5px 20px 0 0" }}>
          <SliderInput
            id="detailLevel"
            name="detailLevel"
            label="Details"
            minValue={1}
            maxValue={3}
            isColumnSlider={false}
            value={ruleMapsData?.detailLevel || 1}
            onChange={setDetailLevel}
          />
        </div>
        {!isModuleViewMode && (
          <button
            title={
              showModules ? "Collapse nodes into modules" : "Expand all nodes"
            }
            className={showModules ? "btn-selected" : ""}
            onClick={handleShowModulesChange}
            style={{ marginRight: "12px" }}
          >
            <span className="material-symbols-outlined">
              {showModules ? "input" : "account_tree"}
            </span>
          </button>
        )}
        {ruleMapsData.isGroupRuleMode === false && (
          <button
            title={
              showPossiblePaths
                ? "Show only relevant node paths"
                : "Show all possible node paths"
            }
            className={showPossiblePaths ? "btn-selected" : ""}
            onClick={handleShowPossiblePathsChange}
            style={{ marginRight: "12px" }}
          >
            <span className="material-symbols-outlined">
              {showPossiblePaths ? "layers" : "layers_clear"}
            </span>
          </button>
        )}
        <button
          title="View in vertical orientation"
          className={layoutDirection === "TB" ? "btn-selected" : ""}
          onClick={() => handleLayoutDirectionChange("TB")}
        >
          <span className="material-symbols-outlined">desktop_portrait</span>
        </button>
        <button
          title="View in horizontal orientation"
          className={layoutDirection === "LR" ? "btn-selected" : ""}
          onClick={() => handleLayoutDirectionChange("LR")}
          style={{ marginRight: "12px" }}
        >
          <span className="material-symbols-outlined">desktop_landscape</span>
        </button>
        <button
          title="Clear selections and reset screen to the default view"
          className="btn btn-secondary"
          onClick={onResetSelections}
          style={{ marginRight: "8px" }}
        >
          <span className="material-symbols-outlined">refresh</span>
        </button>
      </StyledControlPanel>
    </>
  );
}

export default RuleMapFlowToolbar;
