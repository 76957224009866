import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { notifyWarn } from "../../../services/NotificationService";

function GridFreeFormSearchBar({
  placeholderText,
  search,
  setSearch,
  numSetFilters,
  onSubmitSearch,
  showShowFiltersLink = true,
  allowEmptySearch = true,
  alwaysShowMobileFilterLink = false,
  filterLinkEntityLabel = "Filters",
}) {
  function formIsValid() {
    if (
      !allowEmptySearch &&
      _.has(search, "freeFormSearch") &&
      search.freeFormSearch.trim() === ""
    ) {
      notifyWarn("Please enter search terms.");
      return false;
    }

    return true;
  }

  async function handleSearch(event, updatedSearch) {
    if (event) event.preventDefault();
    if (!formIsValid()) return;

    onSubmitSearch(null, updatedSearch);
  }

  async function onSubmit(event) {
    var updatedSearch = { ...search, pageNumber: 1 };
    setSearch(updatedSearch);
    await handleSearch(event, updatedSearch);
  }

  async function handleClearClick() {
    if (_.trim(search.freeFormSearch) !== "") {
      var updatedSearch = { ...search, freeFormSearch: "" };
      setSearch(updatedSearch);
      await handleSearch(null, updatedSearch);
    }
  }

  return (
    <>
      <StyledSearchBar data-testid="form-search-bar">
        <form onSubmit={onSubmit}>
          <i className="material-icons" onClick={onSubmit}>
            search
          </i>

          <input
            id="freeFormSearchInput"
            type="text"
            name="freeFormSearchInput"
            placeholder={placeholderText}
            onChange={({ target }) =>
              setSearch({ ...search, freeFormSearch: target.value })
            }
            value={search.freeFormSearch}
            style={{
              width: "80%",
              maxWidth: "320px",
            }}
          ></input>
          {_.trim(search.freeFormSearch) !== "" && (
            <i
              className="material-icons"
              title="Clear"
              onClick={handleClearClick}
            >
              close
            </i>
          )}
        </form>
        {showShowFiltersLink && (
          <button
            className="btn btn-link d-none d-md-block"
            onClick={() =>
              setSearch({
                ...search,
                showAdvancedFilter: !search.showAdvancedFilter,
              })
            }
          >
            {search.showAdvancedFilter === true
              ? `Hide ${filterLinkEntityLabel}`
              : `Show ${filterLinkEntityLabel}`}
          </button>
        )}
      </StyledSearchBar>
      {showShowFiltersLink && (
        <StyledMobileFilterBar
          className={alwaysShowMobileFilterLink ? "d-flex" : "d-flex d-md-none"}
        >
          <button
            className="btn btn-link"
            onClick={() => setSearch({ ...search, showAdvancedFilter: true })}
          >
            <i className="material-icons">filter_list</i>{" "}
            {`${filterLinkEntityLabel}`} ({numSetFilters})
          </button>
        </StyledMobileFilterBar>
      )}
    </>
  );
}

const StyledSearchBar = styled.div`
  margin: 14px 0 10px 0;
  padding: 0 20px;
  min-height: 60px;
  background: var(--elevated-bg);
  border: 1px solid var(--elevated-border);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  form {
    width: 100%;
    max-width: 500px;
  }

  i {
    cursor: pointer;
    font-size: 24px;
    color: var(--text-light);
  }

  input {
    width: 80%;
    min-width: 200px;
    max-width: 320px;
    margin-left: 13px;
    font-size: 16px;
    line-height: 20px;
    color: var(-text-light);
    outline: none !important;
    padding: 6px 4px;
    border: 0;
    border-bottom: 1px solid var(--text-light);
  }
`;

const StyledMobileFilterBar = styled.div`
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 16px 0;

  i {
    font-size: 30px;
    margin-top: -5px;
  }
`;

export default GridFreeFormSearchBar;
