import React, { useEffect, useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import {
  buildUniqueIdForNode,
  getBadgeColorsFromType,
  NodeTypes,
} from "../rulemaps/RuleMapDataCommon";
import {
  StyledCharblockDiv,
  StyledDescription,
} from "../rulemaps/RuleMapCommonStyles";
import ToggleButtonGroupInput from "../../common/input/ToggleButtonGroupInput";
import { StyledNoResultsDiv } from "../../common/layout/CommonStyledControls";
import ExpandCollapse from "../../common/layout/ExpandCollapse";
import CheckboxInput from "../../common/input/CheckboxInput";
import DatePickerInput from "../../common/input/DatePickerInput";
import { yyyy_mm_dd } from "../../../services/General";
import { parseISO } from "date-fns";
import Badge from "../../common/ui/Badge";
import { useMobile } from "../../../hooks/useMobile";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { emptyGroupRuleMapFilter } from "../../../viewmodels/groupRuleMapsVm";
import { useRuleMaps } from "../../../contexts/RuleMapsContext";

function GroupRuleMapFilterForm({
  search,
  onSearch,
  onReset,
  ruleMap,
  defaultRuleSelections,
  selectedField,
  onFilterItemClick,
}) {
  const { isMobileSize } = useMobile();
  const { ruleMapsData, setRuleMapsData } = useRuleMaps();
  const [unmatchedFields, setUnmatchedFields] = useState([]);
  const [responseFields, setResponseFields] = useState([]);
  const [includedRejectionFields, setIncludedRejectionFields] = useState([]);
  const [excludedRejectionFields, setExcludedRejectionFields] = useState([]);
  const [messageFields, setMessageFields] = useState([]);
  const [variableFields, setVariableFields] = useState([]);

  const variableColors = getBadgeColorsFromType(NodeTypes.InternalVariable);
  const responseColors = getBadgeColorsFromType(NodeTypes.ResponseFieldValue);
  const rejectionColors = getBadgeColorsFromType(NodeTypes.Rejection);
  const messageColors = getBadgeColorsFromType(NodeTypes.RootMessage);
  const unmatchedColors = {
    color: "var(--rulemap-default-text)",
    bgColor: "var(--rulemap-default-bg)",
    bgShadow: "var(--rulemap-default-shadow)",
  };
  const allColors = {
    color: "#FFF",
    bgColor: "#000",
    bgShadow: "var(--rulemap-default-shadow)",
  };

  const filterSelection =
    ruleMapsData?.search?.filterSelection ||
    emptyGroupRuleMapFilter.filterSelection;
  const ruleSelections =
    (ruleMapsData?.search.ruleSelections || []).length > 0
      ? ruleMapsData.search.ruleSelections
      : defaultRuleSelections;
  const filterDate =
    ruleMapsData?.search?.filterDate || emptyGroupRuleMapFilter.filterDate;

  useEffect(() => {
    const fieldLists = buildFieldListsFromRuleMap();

    const searchText = _.trim(search?.freeFormSearch || "");
    if (!_.isEmpty(searchText) && filterSelection === "NONE") {
      setFilterSelection("ALL");
    }

    const filteredFieldLists = filterFieldLists(fieldLists, searchText);

    setIncludedRejectionFields(filteredFieldLists.includedRejectionList);
    setExcludedRejectionFields(filteredFieldLists.excludedRejectionList);
    setResponseFields(filteredFieldLists.responseFieldList);
    setMessageFields(filteredFieldLists.messageList);
    setVariableFields(filteredFieldLists.variableList);
    setUnmatchedFields(filteredFieldLists.unmatchedList);
  }, [
    ruleMap,
    filterSelection,
    search?.freeFormSearch,
    ruleMapsData?.search.ruleSelections,
    filterDate,
  ]);

  function setGroupRuleMapDataFilter(newSearch) {
    setRuleMapsData({
      type: ContextProviderActions.saveGroupRuleMapFilter,
      payload: newSearch,
    });
  }

  function setFilterSelection(selection) {
    const updatedSearch = {
      ...ruleMapsData.search,
      filterSelection: selection,
    };
    setGroupRuleMapDataFilter(updatedSearch);
  }

  function setRuleSelections(selections) {
    const updatedSearch = {
      ...ruleMapsData.search,
      ruleSelections: selections,
    };
    setGroupRuleMapDataFilter(updatedSearch);
  }

  function setFilterDate(date) {
    const updatedSearch = {
      ...ruleMapsData.search,
      filterDate: date,
    };
    setGroupRuleMapDataFilter(updatedSearch);
  }

  function filterListItemBySearchText(array, searchText) {
    let filteredArray = [...array];

    if (!_.isEmpty(searchText)) {
      // Filter by search text
      filteredArray = filteredArray.filter(
        (r) => _.toLower(r.sortLabel).indexOf(_.toLower(searchText)) >= 0
      );
    }

    // Filter by rule
    filteredArray = filteredArray.filter(
      (r) =>
        ruleSelections.findIndex(
          (s) => s.id === r.ruleId && s.checked === true
        ) >= 0
    );

    // Filter by date
    filteredArray = filteredArray.filter(
      (r) =>
        (r.effectiveDate === null || r.effectiveDate <= filterDate) &&
        (r.terminationDate === null || r.terminationDate > filterDate)
    );

    return filteredArray;
  }

  function filterFieldLists(fieldLists, searchText) {
    const filteredFieldLists = {
      unmatchedList: [],
      includedRejectionList: filterListItemBySearchText(
        fieldLists.includedRejectionList,
        searchText
      ),
      excludedRejectionList: filterListItemBySearchText(
        fieldLists.excludedRejectionList,
        searchText
      ),
      responseFieldList: filterListItemBySearchText(
        fieldLists.responseFieldList,
        searchText
      ),
      variableList: filterListItemBySearchText(
        fieldLists.variableList,
        searchText
      ),
      messageList: filterListItemBySearchText(
        fieldLists.messageList,
        searchText
      ),
    };

    // Find all new fields and add them to the unmatchedList array
    let newFields = [];
    newFields = newFields.concat(
      fieldLists.responseFieldList.filter((r) => r.isAdd === true)
    );
    newFields = newFields.concat(
      fieldLists.includedRejectionList.filter((r) => r.isAdd === true)
    );
    newFields = newFields.concat(
      fieldLists.excludedRejectionList.filter((r) => r.isAdd === true)
    );
    newFields = newFields.concat(
      fieldLists.variableList.filter((r) => r.isAdd === true)
    );
    newFields = newFields.concat(
      fieldLists.messageList.filter((r) => r.isAdd === true)
    );

    filteredFieldLists.unmatchedList = newFields;

    return filteredFieldLists;
  }

  function getDropdownLabel(code, description, type) {
    const label = (
      <StyledDescription
        className="flex-row-without-wrap"
        colors={getBadgeColorsFromType(type, true, true)}
      >
        <span className="option-member-name normal-case force-wrap">
          <span className="charblock" style={{ fontSize: "13px" }}>
            {code}
          </span>
          &nbsp;&nbsp;
          <span className="group-rulemap-label">{description}</span>
        </span>
      </StyledDescription>
    );
    return label;
  }

  function buildFilterItem(node, nodeType, key, description) {
    const prefix =
      nodeType === NodeTypes.Rejection ||
      nodeType === NodeTypes.ExcludedRejection ||
      nodeType === NodeTypes.ConditionalNode
        ? "ROOT-"
        : "";
    const item = {
      label: getDropdownLabel(key, description, nodeType),
      sortLabel: `${key} ${description}`,
      value: `${prefix}${buildUniqueIdForNode(node, nodeType)}`,
      type: nodeType,
      ruleId: node.ruleId,
      effectiveDate: node.effectiveDate,
      terminationDate: node.terminationDate,
      isAdd: node.isAdd ?? false,
    };

    return item;
  }

  function buildFieldListsFromRuleMap() {
    let desc;
    const includedRejectionList = (ruleMap.includedRejections || [])
      .map((r) => {
        desc = r.itemValue;
        return buildFilterItem(r, NodeTypes.Rejection, r.itemKey, desc);
      })
      .sort((a, b) => (a.sortLabel > b.sortLabel ? 1 : -1));

    const excludedRejectionList = (ruleMap.excludedRejections || [])
      .map((r) => {
        desc = r.description;
        return buildFilterItem(r, NodeTypes.ExcludedRejection, r.itemKey, desc);
      })
      .sort((a, b) => (a.sortLabel > b.sortLabel ? 1 : -1));

    const responseFields = ruleMap.responseFields || [];

    const responseFieldList = responseFields
      .map((r) => {
        desc =
          r.description !== null
            ? r.description
            : r.displayKey !== null
            ? r.displayKey
            : "DESC. MISSING IN DATA";
        return buildFilterItem(
          r,
          NodeTypes.ResponseFieldValue,
          r.itemKey,
          desc
        );
      })
      .sort((a, b) => (a.sortLabel > b.sortLabel ? 1 : -1));

    const messageFields = ruleMap.messageFields || [];

    const messageList = messageFields
      .map((r) => {
        desc = r.sourceDisplayKey;
        return buildFilterItem(r, r.type, "MSG", desc);
      })
      .sort((a, b) => (a.sortLabel > b.sortLabel ? 1 : -1));

    const variableFields = ruleMap.includedDataValues || [];

    const variableList = variableFields
      .map((r) => {
        desc = r.itemKey;
        return buildFilterItem(r, NodeTypes.InternalVariable, "VAR", desc);
      })
      .sort((a, b) => (a.sortLabel > b.sortLabel ? 1 : -1));

    return {
      includedRejectionList,
      excludedRejectionList,
      responseFieldList,
      messageList,
      variableList,
    };
  }

  function handleFilterSelectionChange(option) {
    setFilterSelection(option);
  }

  function getRuleCheckboxState(id) {
    let checked = false;
    const checkbox = ruleSelections.find((s) => s.id === id);
    if (!checkbox) {
      return false;
    }
    checked = checkbox.checked;
    return checked;
  }

  function handleRuleCheckboxChange({ target }) {
    if (filterSelection === "NONE") {
      setFilterSelection("ALL");
    }

    const rs = [...ruleSelections];
    const checkbox = rs.find((s) => s.id === target.id);
    if (checkbox) {
      checkbox.checked = target.checked;
    }
    setRuleSelections(rs);
  }

  function handleFilterDateChange(date) {
    if (filterSelection === "NONE") {
      setFilterSelection("ALL");
    }

    let dt = date;
    if (dt === null) {
      dt = parseISO(yyyy_mm_dd(new Date()));
    }
    setFilterDate(date);
  }

  function getStyledFilterList(array, title, type, selected, filter) {
    if (filterSelection !== "ALL" && filterSelection !== filter) {
      return <></>;
    }

    const list = (
      <>
        {array.length > 0 && <h4>{title}</h4>}
        <StyledFilterList>
          {array.map((r, idx) => (
            <li
              key={`li-${idx}`}
              className={
                r.value === selected ? "group-rulemap-label-active" : ""
              }
              onClick={() =>
                onFilterItemClick(
                  r.value,
                  type === NodeTypes.Unknown ? r.type : type
                )
              }
            >
              {r.label}
            </li>
          ))}
        </StyledFilterList>
      </>
    );

    return list;
  }

  function getFilterCountForAllArrays() {
    let count = 0;

    if (filterSelection === "ALL" || filterSelection === "RES")
      count += responseFields.length;
    if (filterSelection === "ALL" || filterSelection === "REJ")
      count += includedRejectionFields.length + excludedRejectionFields.length;
    if (filterSelection === "ALL" || filterSelection === "MSG")
      count += messageFields.length;
    if (filterSelection === "ALL" || filterSelection === "VAR")
      count += variableFields.length;
    if (filterSelection === "ALL" || filterSelection === "UNM")
      count += unmatchedFields.length;

    return count;
  }

  function getToggleButtonGroupInputButton(value, label, count, colors) {
    const button = {
      value: value,
      label: (
        <StyledCharblockDiv
          className="charblock"
          colors={colors}
          style={{ width: "99%", height: "45px" }}
        >
          <div className="charblock-label">{label}</div>
          <Badge>{count}</Badge>
        </StyledCharblockDiv>
      ),
    };

    return button;
  }

  const responseCount = responseFields.length;
  const includedRejectionCount = includedRejectionFields.length;
  const excludedRejectionCount = excludedRejectionFields.length;
  const messageCount = messageFields.length;
  const variableCount = variableFields.length;
  const unmatchedCount = unmatchedFields.length;
  const rejectionCount = includedRejectionCount + excludedRejectionCount;
  const totalCount =
    responseCount +
    includedRejectionCount +
    excludedRejectionCount +
    messageCount +
    variableCount +
    unmatchedCount;

  const filterCount = getFilterCountForAllArrays();
  const allEmpty = filterCount === 0;

  return (
    <>
      <form onSubmit={onSearch} onReset={onReset}>
        <div className="filter-search-form">
          <ExpandCollapse
            id="groupRuleScreenRuleSelection"
            title="Rule Selection"
            defaultState={false}
          >
            <StyledCheckboxList>
              {ruleMap.ruleMetadata.map((r, idx) => (
                <CheckboxInput
                  key={`rulechk-${idx}`}
                  id={r.id}
                  label={r.name}
                  onChange={handleRuleCheckboxChange}
                  placeholder=""
                  name={r.id}
                  showLabelInline={true}
                  checked={getRuleCheckboxState(r.id)}
                />
              ))}
            </StyledCheckboxList>
          </ExpandCollapse>
          <ExpandCollapse
            id="groupRuleScreenDateSelection"
            title="Date Selection"
            defaultState={false}
          >
            <DatePickerInput
              id="filterDate"
              name="filterDate"
              label="Show fields effective on:"
              value={filterDate}
              placeholder="Filter Date"
              onChange={handleFilterDateChange}
              showTimeInput={false}
            />
          </ExpandCollapse>
          <ExpandCollapse
            id="groupRuleScreenFieldSelection"
            title="Field Selection"
            defaultState={true}
            style={{ marginTop: "40px" }}
          >
            <div style={{ marginLeft: "-20px" }}>
              <ToggleButtonGroupInput
                id="filterSelection"
                name="filterSelection"
                label=""
                customWidth="60px"
                labelStyle={{ display: "none" }}
                controlClass="btn-group-toolbar"
                buttonClass="btn-group-btn"
                options={[
                  getToggleButtonGroupInputButton(
                    "ALL",
                    "ALL",
                    totalCount,
                    allColors
                  ),
                  getToggleButtonGroupInputButton(
                    "UNM",
                    "NEW",
                    unmatchedCount,
                    unmatchedColors
                  ),
                  getToggleButtonGroupInputButton(
                    "RES",
                    "RES",
                    responseCount,
                    responseColors
                  ),
                  getToggleButtonGroupInputButton(
                    "REJ",
                    "REJ",
                    rejectionCount,
                    rejectionColors
                  ),
                  getToggleButtonGroupInputButton(
                    "VAR",
                    "VAR",
                    variableCount,
                    variableColors
                  ),
                  getToggleButtonGroupInputButton(
                    "MSG",
                    "MSG",
                    messageCount,
                    messageColors
                  ),
                ]}
                value={filterSelection}
                onChange={handleFilterSelectionChange}
              />
            </div>
            {allEmpty && filterSelection !== "ALL" ? (
              <StyledNoResultsDiv style={{ marginTop: "20px" }}>
                <p>
                  <i className="material-icons">search_off</i>
                </p>
                <p>Filter returned no results</p>
              </StyledNoResultsDiv>
            ) : (
              <StyledResultsContent>
                {getStyledFilterList(
                  unmatchedFields,
                  "New Fields",
                  NodeTypes.Unknown,
                  selectedField?.value,
                  "UNM"
                )}
                {getStyledFilterList(
                  responseFields,
                  "Response Fields",
                  NodeTypes.ResponseFieldValue,
                  selectedField?.value,
                  "RES"
                )}
                {getStyledFilterList(
                  includedRejectionFields,
                  "Included Rejections",
                  NodeTypes.Rejection,
                  selectedField?.value,
                  "REJ"
                )}
                {getStyledFilterList(
                  excludedRejectionFields,
                  "Excluded Rejections",
                  NodeTypes.ExcludedRejection,
                  selectedField?.value,
                  "REJ"
                )}
                {getStyledFilterList(
                  messageFields,
                  "Messages",
                  NodeTypes.Unknown,
                  selectedField?.value,
                  "MSG"
                )}
                {getStyledFilterList(
                  variableFields,
                  "Internal Variables",
                  NodeTypes.InternalVariable,
                  selectedField?.value,
                  "VAR"
                )}
              </StyledResultsContent>
            )}
          </ExpandCollapse>
        </div>
        {isMobileSize && (
          <div className="grid-search-form-action-row">
            <input type="submit" value={"Apply"} className="btn btn-primary" />
            <input type="reset" value="Clear" className="btn btn-secondary" />
          </div>
        )}
      </form>
    </>
  );
}

const StyledResultsContent = styled.div`
  margin-top: 20px;

  h4 {
    margin-top: 10px;
  }
`;

const StyledFilterList = styled.ul`
  margin-top: 10px;
  padding-left: 5px;

  li {
    text-indent: 0;
    list-style-type: none;
    cursor: pointer;
    margin-bottom: 2px;
    border-bottom: 1px solid transparent;

    &:hover span.group-rulemap-label {
      color: var(--text-highlight);
      background-color: var(--table-hover-row-bg);
    }

    &:active span.group-rulemap-label {
      color: var(--text-highlight);
      background-color: transparent;
    }

    &.group-rulemap-label-active {
      color: var(--text-highlight);
      background-color: var(--table-hover-row-bg);
      border-radius: 0 20px 20px 0;

      span.group-rulemap-label {
        color: var(--text-highlight);
        background-color: var(--table-hover-row-bg);
      }
    }
  }
`;

const StyledCheckboxList = styled.div`
  div.form-group {
    margin-top: 0px;

    label {
      font-size: 14px;
    }
  }
`;

export default GroupRuleMapFilterForm;
