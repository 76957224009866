import React from "react";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import RuleTableDataValueByType from "../ruletables/RuleTableDataValueByType";
import HelpLink from "../../common/ui/HelpLink";

function GroupRuleMapRuleTableDialog({
  editDataRow,
  setEditDataRow,
  columnSpecs,
  errors,
  showDialog,
  onCloseDialog,
  onSaveDialog,
}) {
  function initializeNewRowValuesArray(newRow) {
    // Create empty string values for each column in the spec. This ensures indexing specific columns will succeed.
    newRow.values = [];
    for (let i = 0; i < columnSpecs.length; i++) {
      newRow.values.push("");
    }
  }

  function handleChange({ target }) {
    const _newRow = _.cloneDeep(editDataRow);
    const colIndex = columnSpecs.findIndex((c) => c.id === target.name);

    if (!_newRow.values) {
      initializeNewRowValuesArray(_newRow);
    }

    _newRow.values[colIndex] = target.value;
    setEditDataRow(_newRow);
  }

  function handleDateChange({ target }) {
    setEditDataRow({ ...editDataRow, [target.name]: target.value });
  }

  const isEditMode = editDataRow && editDataRow.id;
  let value = "";

  // columnSpec, error, value, onChange
  return (
    <>
      <Modal show={showDialog} onHide={onCloseDialog}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editDataRow.isScheduleChange
              ? "Schedule Rule Data Change"
              : isEditMode
              ? "Edit Rule Data"
              : "Add Rule Data"}
            <HelpLink
              path="/Processing-Rules/Group-Rule-Map-Screen&anchor=rule-data-dialog"
              label=""
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {columnSpecs.length === 0 ? (
            <i style={{ color: "var(--text-dark)" }}>
              No columns found in rule table definition!
            </i>
          ) : (
            <div className="row">
              {editDataRow.isScheduleChange && (
                <p>
                  This will insert a new data row that begins on the Effective
                  Date and will schedule the old row to terminate the day
                  before.
                </p>
              )}
              {columnSpecs.map((colSpec, idx) => {
                value = editDataRow.values[idx] || "";

                return (
                  <div key={`col-${colSpec.id}`} className="col col-12">
                    <RuleTableDataValueByType
                      key={colSpec.id}
                      columnSpec={colSpec}
                      value={value}
                      error={errors[colSpec.id]}
                      onChange={handleChange}
                      autoFocus={idx === 0}
                    />
                  </div>
                );
              })}

              <div className="col col-12">
                <RuleTableDataValueByType
                  key="effectiveDate"
                  columnSpec={{
                    id: "effectiveDate",
                    name: "Effective Date",
                    isKey: true,
                    controlType: "Input",
                    valueType: "Date",
                    validValue: [],
                  }}
                  value={editDataRow.effectiveDate}
                  error={errors.effectiveDate}
                  onChange={handleDateChange}
                  autoFocus={false}
                />
              </div>
              <div className="col col-12">
                <RuleTableDataValueByType
                  key="terminationDate"
                  columnSpec={{
                    id: "terminationDate",
                    name: "Termination Date",
                    isKey: false,
                    controlType: "Input",
                    valueType: "Date",
                    validValue: [],
                  }}
                  value={editDataRow.terminationDate}
                  error={errors.terminationDate}
                  onChange={handleDateChange}
                  autoFocus={false}
                />
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary btn-with-icon"
            onClick={onSaveDialog}
            style={{
              minWidth: "86px",
            }}
          >
            <span className="material-icons">check</span>
            Save
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onCloseDialog}
            style={{ marginLeft: "12px" }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default GroupRuleMapRuleTableDialog;
